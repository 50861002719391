import React from "react";
import { Col, Container, Row } from "reactstrap";
import AuthLanguage from "../components/auth/AuthLanguage";
import Main from "../components/Main";
import Footer from "../components/Footer";
import DashboardLayout from "../layouts/Dashboard";
import SessionStorage from "../axios/SessionStorage";

const FullPageWidth = ({ children }) => (
  <React.Fragment>
    {SessionStorage.getAccessToken() ? "" : <AuthLanguage />}
    <Main>
      <Container className="h-100">
        <Row className="h-100">
          <Col sm={SessionStorage.getAccessToken() ? "" : "10"} className={SessionStorage.getAccessToken() ? "": "mx-auto d-table h-100"}>
            <div className="d-table-cell align-middle">{children}</div>
          </Col>
        </Row>
        {SessionStorage.getAccessToken() ? "" : (<Row>
          <Col sm="12">
            <Footer />
          </Col>
        </Row>)}
      </Container>
    </Main>
  </React.Fragment>
);

const FullPageWithLayout = ({children}) => {
  return SessionStorage.getAccessToken() != null ? 
    <DashboardLayout>
      <FullPageWidth>{children}</FullPageWidth>
    </DashboardLayout> : 
    <FullPageWidth>{children}</FullPageWidth>  
}

export default FullPageWithLayout;
