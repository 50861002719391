import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Container, Row } from "reactstrap";
import Axios from "../../axios/Axios";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import Message from "../../components/messages/Message";
import MessageList from "../../components/messages/MessageList";
import ModalError from "../../components/modals/ModalError";
import ApiEnums from "../../enums/ApiEnums";
import { GenerateBreadcrumb } from "../../helpers/GenerateBreadcrumb";
import { store } from "../../redux/store/index";

/*
 * Class for the page for new messages
 */
class NewMessagePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasAccess: false,

      showModalError: false,

      draftMessage: this.props.location.state
        ? this.props.location.state.draftMessage
        : null,
    };
    this.translation = this.props.t;
    this.id = this.props.match.params.id;

    this.mainError = "";
    this.errorReason = "";
    this.errorResponse = "";

    this.toggleModalError = this.toggleModalError.bind(this);
    this.errorHandler = this.errorHandler.bind(this);
  }

  // Toggles the Boolean that affects appearance of modal error dialog box
  toggleModalError() {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  }

  async componentDidMount() {
    let storeState = store.getState();
    let accountDetails = storeState.account.accountDetails;
    this.props.i18n.changeLanguage(accountDetails.language);

    await Axios.clientService
      .get(
        `${ApiEnums.WhistleblowerCase}/${this.id}`
      )
      .then((response) => {
        this.setState({
          hasAccess: true,
          subject: response.data.title,
        });
      })
      .catch((error) => {
        this.errorHandler("Verification", error);
      });

      await Axios.clientService
      .get(
        `whistleblower-case-messages/${this.id}`
      )
      .then((response) => {
        this.setState({
          hasAccess: true,
          messages: response.data,
        });
      })
      .catch((error) => {
        this.errorHandler("Verification", error);
      });
  }

  // Catches the errors encountered within this page
  errorHandler(currentOperation, error) {
    if (currentOperation === "Verification") {
      this.mainError = this.translation(`errorMessages.userVerificationError`);
    }
    if (error.response?.data?.errorKey === "userunauthorized") {
      this.errorReason = this.translation(`errorMessages.accessForbidden`);
    } else {
      this.errorReason = this.translation(`errorMessages.failedToCommunicate`);
    }
    this.errorResponse = error.message;
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  }

  render() {
    return (
      <Container fluid>
        <Header>
          <HeaderTitle>
            {this.translation(`newMessagePage.newMessage`)}
          </HeaderTitle>
          {GenerateBreadcrumb(this.props.location.pathname, "Cases", "/")}
        </Header>
        <Row>
          {this.state.hasAccess && (
            <Message subject = {this.state.subject} draftMessage={this.state.draftMessage} />
          )}
        </Row>
        <Row>
          {this.state.hasAccess && (
            <MessageList messages={this.state.messages} id={this.id} parentPage="New Messages" />
          )}
        </Row>
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.mainError}
          errorReason={this.errorReason}
          errorResponse={this.errorResponse}
          modalTitle="Error"
        ></ModalError>
      </Container>
    );
  }
}

export default withRouter(withTranslation()(NewMessagePage));
