import React from "react";
import Loader from "react-loader-spinner";
import { usePromiseTracker } from "react-promise-tracker";
import { backendUrl } from "../axios/Axios";
import BrowserEnums from "../enums/BrowserEnums";
import LastLanguageEnums from "../enums/LastLanguageEnums";

var moment = require("moment-timezone");

let minVersionNumber = 1;

/*
 * This function checks if the object is undefined or empty
 */
export function isEmpty(obj) {
  if (obj === undefined) return true;
  if (typeof obj === "object" && obj !== null) {
    if (Array.isArray(obj)) {
      if (obj.length === 0) {
        return true;
      }
    } else if (Object.keys(obj).length === 0) {
      return true;
    }
    return false;
  }
}

/*
 * This constant allows a loading indicator to be seen when
 * a Promise call is ongoing
 */
export const LoadingIndicator = () => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          width: "100%",
          height: "100",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader className="loader" type="ThreeDots" height="100" width="100" />
      </div>
    )
  );
};

/**
 * This function formats date based on the user's browser settings
 * and would also allow to add time on the format
 * @param date
 * This parameter would only show the date in  format type 4 (Month D, YYYY with month spelled out)
 * @param showTime
 * This parameter is a combination of date and time
 * @returns date that is parsed into a moment
 */
export function formatDate(date, showTime = false) {
  // LLL = Month, day, and year with time
  // LL = Month, day and year only
  const options = showTime ? "LLL" : "LL";

  return moment.tz(date, moment.tz.guess()).format(options);
}

/**
 * Removes object from array. To be removed, the object's attribute value must match the passed value parameter
 * @param {*} array Array to remove object from
 * @param {*} attribute Attribute of the object
 * @param {*} value
 */
export function removeByAttr(array, attribute, value) {
  var index = array.length;
  while (index--) {
    if (
      array[index] &&
      array[index].hasOwnProperty(attribute) &&
      arguments.length > 2 &&
      array[index][attribute] === value
    ) {
      array.splice(index, 1);
    }
  }
  return array;
}

/**
 * Returns the webpage associated with the browser name
 * @param {*} browser the name of the browser
 */
export function generateBrowserLink(browser) {
  let browserLink = "/";
  switch (browser) {
    case BrowserEnums.CHROME:
      browserLink = "https://www.google.com/chrome/";
      break;
    case BrowserEnums.FIREFOX:
      browserLink = "https://www.mozilla.org/en-US/firefox/";
      break;
    case BrowserEnums.EDGE:
      browserLink = "https://www.microsoft.com/en-us/edge";
      break;
    case BrowserEnums.SAFARI:
      browserLink = "https://www.apple.com/safari/";
      break;
    default:
      break;
  }
  return browserLink;
}

/**
 * Checks if the browser's current language matches those in the
 * language list (as dictated by LastLanguageEnums)
 * @param {*} language the current language of the browser
 */
export function getBrowserLanguage(language) {
  var languageArray = Object.values(LastLanguageEnums).map((lang) =>
    lang.toLowerCase()
  );

  if (languageArray.indexOf(language) === -1) {
    return "en";
  }

  return language;
}

export function resetStyleSheet() {
  Array.from(document.querySelectorAll('link[id="rootStyleSheet"]')).forEach(
    (style) => {
      style.parentNode.removeChild(style);
    }
  );
  loadStyleSheet();
}

export function loadStyleSheet() {
  var link = document.createElement("link");
  link.id = "rootStyleSheet";
  link.href = `${backendUrl}style/get-css?version=${minVersionNumber}`; //adding version number prevents issue on Firefox caching the initial page 
  link.type = "text/css";
  link.rel = "stylesheet";
  document.getElementsByTagName("head")[0].appendChild(link);

  minVersionNumber++;
}

export function isJSON(object) {
  try {
    // ensure that JSON.parse will not return null or an empty string
    if (JSON.parse(object) !== "" && JSON.parse(object) !== null) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
}

