import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import i18n from "../../i18n";

/**
 * ModalOK contains commands that would enable a modal dialog for miscellaneous dialog
 * to appear, which only requests an "OK" input from the user as confirmation
 */
const ModalOK = (props) => {
  const { modalTitle, modalBodyText, isOpen, onClose, size } = props;

  const [modal, setModal] = useState(isOpen);

  const toggle = (e) => {
    onClose && onClose(e);
    setModal(!modal);
  };

  useEffect(() => {
    setModal(isOpen);
  }, [isOpen]);

  var AcceptButton = (
    <Button color="primary" onClick={toggle}>
      {i18n.t(`commonButtonTexts.okay`)}
    </Button>
  );

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} scrollable={true} size={size}>
        <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
        <ModalBody>{modalBodyText}</ModalBody>
        <ModalFooter>{AcceptButton}</ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalOK;
