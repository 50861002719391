import { faEdit, faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Input,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import Axios from "../../axios/Axios";
import FadeAlert from "../../components/FadeAlert";
import ModalError from "../../components/modals/ModalError";
import ApiEnums from "../../enums/ApiEnums";
import { LoadingIndicator } from "../../helpers/GenericHelper";
import { store } from "../../redux/store/index";

/*
 * Class for generating the card for Case details
 */
class CaseDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      case: {},
      caseTitle: null,

      detailSuccessMessage: "",

      editCase: false,
      showModalError: false,
      errors: {},

    };
    this.caseData = this.props.caseData;

    this.translation = this.props.t;
    this.firstIndex = 0;

    this.currentUserId = store.getState().account.accountDetails.id;

    this.mainError = "";
    this.errorReason = "";
    this.errorResponse = "";

    this.toggleModalError = this.toggleModalError.bind(this);
    this.showEditCase = this.showEditCase.bind(this);
    this.updateCaseTitle = this.updateCaseTitle.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.errorHandler = this.errorHandler.bind(this);
    this.handleCaseTitleChange = this.handleCaseTitleChange.bind(this);
  }

  // Commands to execute when the component is mounting.
  componentDidMount() {
    this.setState({
      case: this.caseData,
      caseTitle: this.caseData.title,
    });
  }

  // Toggles the Boolean that affects appearance of modal error dialog box
  toggleModalError() {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  }

  // This method will allow the case title to become editable
  showEditCase() {
    this.setState({
      editCase: !this.state.editCase,
    });
  }

  // Updates the case title
  async updateCaseTitle() {
    if (this.handleValidation()) {
      let newCase = {
        id: this.caseData.id,
        title: this.state.caseTitle,
        user: {
          id: this.state.case.user.id,
        },
        metaInformation: this.state.case.metaInformation,
      };
      await Axios.clientService
        .put(ApiEnums.WhistleblowerCase, newCase)
        .then((response) => {
          this.setState(
            {
              case: response.data,
              caseTitle: response.data.title,
            },
            () => {
              this.showEditCase();
              this.setState({
                detailSuccessMessage: this.translation(`caseDetails.detailsUpdated`),
              });

              setTimeout(() => {
                this.setState({
                  detailSuccessMessage: "",
                });
              }, 5000);
            }
          );
        })
        .catch((error) => {
          this.errorHandler("Update Case Title", error);
        });
    }
  }

  // Handles the validation of the title field.
  handleValidation() {
    let titleCheck = this.state.caseTitle;
    let errors = {};
    let formIsValid = true;

    if (titleCheck === "") {
      formIsValid = false;
      errors["title"] = this.translation(`fieldWarnings.fieldIsRequired`);
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  // Catches the errors encountered within this page
  errorHandler(currentOperation, error) {
    if (currentOperation === "Update Case Title") {
      this.mainError = this.translation(`errorMessages.caseTitleUpdateError`);
    }
    this.errorReason = this.translation(`errorMessages.failedToCommunicate`);
    this.errorResponse = error.message;
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  }

  // Handles changes made to the Case Title field
  handleCaseTitleChange(e) {
    this.setState({ caseTitle: e.target.value });
  }

  render() {
    return (
      <>
        <Card id="caseDetailCard">
          <LoadingIndicator />
          <CardHeader>
            {this.state.detailSuccessMessage !== "" && (
              <FadeAlert color="success">
                {this.state.detailSuccessMessage}
              </FadeAlert>
            )}
            {this.state.editCase ? (
              <div className="card-actions float-right">
                <Button
                  color="primary"
                  size="m"
                  onClick={this.updateCaseTitle}
                  id="save-button"
                >
                  <UncontrolledTooltip target="save-button">
                    {this.translation(`commonButtonTexts.save`)}
                  </UncontrolledTooltip>
                  <FontAwesomeIcon icon={faSave} style={{ "vertical-align": "middle" }} />{" "}{this.translation(`commonButtonTexts.save`)}
                </Button>
                <span> </span>
                <Button
                  color="primary"
                  size="m"
                  onClick={this.showEditCase}
                  id="cancel-button"
                >
                  <FontAwesomeIcon icon={faTimes} style={{ "vertical-align": "middle" }} />{" "}{this.translation(`commonButtonTexts.cancel`)}
                </Button>
                <UncontrolledTooltip target="cancel-button">
                  {this.translation(`commonButtonTexts.cancel`)}
                </UncontrolledTooltip>
              </div>
            ) : (
              <div className="card-actions float-right">
                <Button
                  color="primary"
                  size="m"
                  id="editCaseTitle"
                  onClick={this.showEditCase}
                >
                  <FontAwesomeIcon icon={faEdit} />{" "}{this.translation(`commonButtonTexts.edit`)}
                </Button>
                <UncontrolledTooltip target="editCaseTitle">
                  {this.translation(`commonButtonTexts.edit`)}
                </UncontrolledTooltip>
              </div>
            )}
            <CardTitle>
              <h1>{this.translation(`caseDetailsPage.caseDetailsHeader`)}</h1>
            </CardTitle>
          </CardHeader>
          <CardBody>
            <Table>
              <tbody>
                <tr>
                  <th>{this.translation(`commonText.caseTitle`)}:</th>
                  {this.state.editCase ? (
                    <td className="word-wrap">
                      <Input
                        id="caseTitleTextBox"
                        bsSize="lg"
                        type="string"
                        onChange={this.handleCaseTitleChange}
                        value={this.state.caseTitle}
                      ></Input>
                      <span className="error">
                        <Badge color="danger">
                          {this.state.errors["title"]}
                        </Badge>
                      </span>
                    </td>
                  ) : (
                    <td className="word-wrap">{this.state.case.title}</td>
                  )}
                </tr>
              </tbody>
            </Table>
          </CardBody>
        </Card>
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.mainError}
          errorReason={this.errorReason}
          errorResponse={this.errorResponse}
          modalTitle="Error"
        ></ModalError>
      </>
    );
  }
}

export default withRouter(withTranslation()(CaseDetails));
