import { REHYDRATE } from "redux-persist";
import * as types from "../constants";

const initialState = {
  accountDetails: {
    authorities: [],
  },
  company: undefined,
};

//reducer for account
//handles all the changes based on the type of action
export default function reducer(state = initialState, actions) {
  let updatedState = Object.assign({}, state);
  switch (actions.type) {
    case REHYDRATE:
      updatedState.accountDetails = actions.payload?.account?.accountDetails;
      return updatedState;
    case types.SET_ACCOUNT_DETAILS:
      updatedState.accountDetails = actions.accountDetails;
      return updatedState;
    case types.RESET_ACCOUNT_DETAILS:
      updatedState.accountDetails = initialState;
      return updatedState;
    case types.SET_COMPANY_DETAILS:
      updatedState.company = actions.company;
      return updatedState;
    default:
      return state;
  }
}
