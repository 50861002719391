import { faEye, faEyeSlash, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AvField,
  AvForm,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";
import _debounce from "lodash.debounce";
import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  UncontrolledTooltip,
  Row,
  Col,
  Label,
} from "reactstrap";
import axios from "../../axios/Axios";
import FadeAlert from "../../components/FadeAlert";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { LoadingIndicator, isEmpty } from "../../helpers/GenericHelper";
import i18n from "../../i18n";

/**
 * React hook for displaying the page for changing of password
 */
export default function ChangePassword() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [isPasswordSame, setPasswordSame] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showOldPassword, showHideOldPassword] = useState(false);
  const [showNewPassword, showHideNewPassword] = useState(false);
  const [showRepeatPassword, showHideRepeatPassword] = useState(false);
  const [passwordSettings, setPasswordSettings] = useState({});
  const [successMessage, setSuccessMessage] = useState("");

  let timeout;

  useEffect(() => {}, []);

  function handleShowHideOldPassword(event) {
    event.preventDefault();
    showHideOldPassword(!showOldPassword);
  }

  function handleShowHideNewPassword(event) {
    event.preventDefault();
    showHideNewPassword(!showNewPassword);
  }

  function handleShowHideRepeatPassword(event) {
    event.preventDefault();
    showHideRepeatPassword(!showRepeatPassword);
  }

  function handleSetOldPassword(event) {
    setOldPassword(event.target.value);
  }

  function handleSetNewPassword(event) {
    setNewPassword(event.target.value);
    setPasswordSame(event.target.value === repeatPassword);
  }

  function handleSetRepeatPassword(event) {
    setRepeatPassword(event.target.value);
    setPasswordSame(event.target.value === newPassword);
  }

  const validateConfirmPassword = _debounce((value, ctx, input, cb) => {
    clearTimeout(timeout);

    // simulate network call
    timeout = setTimeout(() => {
      if (value !== newPassword) {
        cb(i18n.t(`fieldWarnings.passwordsDoNotMatch`));
      } else {
        cb(true);
      }
    }, 100);
  }, 100);

  function submitChangePassword() {
    setErrorMessage(null);
    setSuccessMessage(null);

    const data = {
      currentPassword: oldPassword,
      newPassword: newPassword,
    };
    axios.account
      .post("account/change-password", data)
      .then(() => {
        setSuccessMessage(i18n.t(`resetpassword.successMessage`));
      })
      .catch((error) => {
        setErrorMessage(error.response.data.title);
      });
  }

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>{i18n.t(`routes.changePassword`)}</HeaderTitle>
        <Card>
          <LoadingIndicator />
          <CardHeader>
            <Row>
              <Col>
                <Label>
                  {i18n.t(`settings.minumumLengthHint`, {
                    length: passwordSettings?.minLength ?? 5,
                  })}
                </Label>
              </Col>
            </Row>
            <Row>
              <Col>
                <Label>{i18n.t(`settings.specialCharacterHint`)}</Label>
              </Col>
            </Row>
            <Row>
              <Col>
                {errorMessage && (
                  <FadeAlert color="danger">{errorMessage}</FadeAlert>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {successMessage && (
                  <FadeAlert color="success">{successMessage}</FadeAlert>
                )}
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="6">
                <AvForm onValidSubmit={submitChangePassword}>
                  <AvGroup className="passwordField">
                    <AvInput
                      name="oldPassword"
                      placeholder={i18n.t(`fieldLabels.oldPassword`)}
                      type={showOldPassword ? "text" : "password"}
                      value={oldPassword}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: i18n.t(`fieldWarnings.fieldIsRequired`),
                        },
                      }}
                      onChange={handleSetOldPassword}
                    />
                    <FontAwesomeIcon
                      icon={showOldPassword ? faEyeSlash : faEye}
                      onClick={handleShowHideOldPassword}
                      className="eyeIcon"
                    />
                  </AvGroup>

                  <AvGroup className="passwordField">
                    <AvField
                      name="newPassword"
                      placeholder={i18n.t(`fieldLabels.newPassword`)}
                      type={showNewPassword ? "text" : "password"}
                      value={newPassword}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: i18n.t(`fieldWarnings.fieldIsRequired`),
                        },
                        minLength: {
                          value: passwordSettings?.minLength ?? 5,
                          errorMessage: i18n.t(
                            `fieldWarnings.passwordMinLength`,
                            {
                              length: passwordSettings?.minLength ?? 5,
                            }
                          ),
                        },
                        pattern: {
                          value:
                            passwordSettings?.acceptedCharacters?.length > 0
                              ? `^[A-Za-z0-9${passwordSettings.acceptedCharacters.join(
                                  ""
                                )}]+$`
                              : "^.*",
                          errorMessage: i18n.t(`fieldWarnings.passwordRules`),
                        },
                      }}
                      onChange={handleSetNewPassword}
                    />
                    <FontAwesomeIcon
                      icon={showNewPassword ? faEyeSlash : faEye}
                      onClick={handleShowHideNewPassword}
                      className="eyeIcon"
                    />
                  </AvGroup>

                  <AvGroup className="passwordField">
                    <AvField
                      name="repeatPassword"
                      placeholder={i18n.t(`fieldLabels.repeatPassword`)}
                      type={showRepeatPassword ? "text" : "password"}
                      value={repeatPassword}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: i18n.t(`fieldWarnings.fieldIsRequired`),
                        },
                        minLength: {
                          value: 4,
                          errorMessage: i18n.t(
                            `fieldWarnings.passwordMinLength`,
                            {
                              length: passwordSettings.minLength,
                            }
                          ),
                        },
                        async: validateConfirmPassword,
                      }}
                      onChange={handleSetRepeatPassword}
                    />
                    <FontAwesomeIcon
                      icon={showRepeatPassword ? faEyeSlash : faEye}
                      onClick={handleShowHideRepeatPassword}
                      className="eyeIcon"
                    />
                  </AvGroup>
                  <Button color="primary" id="save" disabled={!isPasswordSame}>
                    <FontAwesomeIcon icon={faSave} />
                    {i18n.t(`commonButtonTexts.save`)}
                  </Button>
                  <UncontrolledTooltip target="save">
                    {i18n.t(`commonButtonTexts.save`)}
                  </UncontrolledTooltip>
                </AvForm>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Header>
    </Container>
  );
}
