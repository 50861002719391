import axios from "axios";
import SessionStorage from "./SessionStorage";

const sessionStorageService = SessionStorage.getService();

// Use for adding the authorization header located in session storage
// on axios request.
axios.interceptors.request.use(
  (config) => {
    const token = sessionStorageService.getAccessToken();
    if (token) {
      config.headers["Authorization"] = token;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Interceptor for response, if error 401 - not authorized return to login page
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      if (window.location.pathname !== "/sign-in") {
        window.location.href = "/auth/sign-out";
      }
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default axios;
