import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
import { ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader, Container } from "reactstrap";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import axios from "../../axios/Axios";
import ModalOK from "../../components/modals/ModalOK";
import { language } from "../../helpers/Constants";
import { isEmpty, isJSON } from "../../helpers/GenericHelper";
import i18n from "../../i18n";
import { setCompanyDetails } from "../../redux/actions/AccountActions";
import { store } from "../../redux/store/index";

export default function CompanyInformationSettings() {

  const [initialInformation, setInitialInformation] = useState({});
  // Languages
  const [languages, setLanguages] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState("");
  // Company Name
  const [companyName, setCompanyName] = useState("");
  const [currentCompanyName, setCurrentCompanyName] = useState("");
  // Providers
  const [provider, setProvider] = useState("");
  const [currentProvider, setCurrentProvider] = useState(
    ""
  );
  // Company Information
  const [companyInformation, setCompanyInformation] = useState({});
  // Set Privacy
  const [privacy, setPrivacy] = useState("");
  const [currentPrivacy, setCurrentPrivacy] = useState(
    ""
  );
  // Set Contact
  const [contact, setContact] = useState("");
  const [currentContact, setCurrentContact] = useState(
    ""
  );
  // Set Languages
  const [imprint, setImprint] = useState("");
  const [currentImprint, setCurrentImprint] = useState(
    ""
  );
  // Used to show the modal
  const [showModal, setToggleModal] = useState("");
  const [showModalError, setToggleModalError] = useState("");

  // Toggles the Boolean that affects appearance of modal dialog box
  const toggleModal = () => {
    setToggleModal(!showModal);
  };

  // Toggles the Boolean that affects appearance of modal error box
  const toggleModalError = () => {
    setToggleModalError(!showModalError);
  };

  const windowLanguage = Cookies.get(language);

  useEffect(() => {
    axios.clientService.get(`company-information`).then((response) => {
      if (!isEmpty(response)) {
        setInitialInformation(response.data);
        // Set Languages
        setLanguages(response.data.languages);
        setCurrentLanguage(windowLanguage);
        // Set Company Information
        setCompanyInformation(response.data.companyInformation);
        // Set Company Name
        setCompanyName(response.data.companyInformation?.companyName);
        setCurrentCompanyName(
          isJSON(response.data.companyInformation?.companyName)
            ? JSON.parse(response.data.companyInformation?.companyName)[
            windowLanguage
            ]
            : ""
        );
        // Set Providers
        setProvider(response.data.companyInformation?.providerName);
        if (
          isJSON(response.data.companyInformation?.providerName) &&
          JSON.parse(
            response.data.companyInformation?.providerName
          ).hasOwnProperty(windowLanguage)
        ) {
          setCurrentProvider(
            draftToHtml(
              JSON.parse(response.data.companyInformation?.providerName)[
              windowLanguage
              ] ?? null
            )
          );
        }
        // Set Privacy
        setPrivacy(response.data.miscInformation?.privacy);
        if (
          isJSON(response.data.miscInformation?.privacy) &&
          JSON.parse(response.data.miscInformation?.privacy).hasOwnProperty(
            windowLanguage
          )
        ) {
          setCurrentPrivacy(
            draftToHtml(
              JSON.parse(response.data.miscInformation?.privacy)[
              windowLanguage
              ] ?? null
            )
          );
        }
        // Set Languages
        setContact(response.data.miscInformation?.contact);
        if (
          isJSON(response.data.miscInformation?.contact) &&
          JSON.parse(response.data.miscInformation?.contact).hasOwnProperty(
            windowLanguage
          )
        ) {
          setCurrentContact(
            draftToHtml(
              JSON.parse(response.data.miscInformation?.contact)[
              windowLanguage
              ] ?? null
            )
          );
        }
        // Set Languages
        setImprint(response.data.miscInformation?.imprint);
        if (
          isJSON(response.data.miscInformation?.imprint) &&
          JSON.parse(response.data.miscInformation?.imprint).hasOwnProperty(
            windowLanguage
          )
        ) {
          setCurrentImprint(
            draftToHtml(
              JSON.parse(response.data.miscInformation?.imprint)[
              windowLanguage
              ] ?? null
            )
          );
        }
      }
    });
  }, []);

  useEffect(() => {
    let companyNameChanged = isJSON(companyName) ? JSON.parse(companyName) : {};

    if (currentCompanyName) {
      companyNameChanged[currentLanguage] = currentCompanyName;
    }
    else {
      companyNameChanged[currentLanguage] = "";
    }

    setCompanyName(JSON.stringify(companyNameChanged));
  }, [currentCompanyName])

  useEffect(() => {
    let providerChanged = isJSON(provider) ? JSON.parse(provider) : {};

    if (currentProvider === "<p><br></p>") {
      delete providerChanged[currentLanguage];
    }
    else {
      providerChanged[currentLanguage] = convertForSaving(currentProvider);
    }

    setProvider(JSON.stringify(providerChanged));

  }, [currentProvider])

  useEffect(() => {
    let privacyChanged = isJSON(privacy) ? JSON.parse(privacy) : {};

    if (currentPrivacy === "<p><br></p>") {
      delete privacyChanged[currentLanguage];
    }
    else {
      privacyChanged[currentLanguage] = convertForSaving(currentPrivacy);
    }

    setPrivacy(JSON.stringify(privacyChanged))
  }, [currentPrivacy])

  useEffect(() => {
    let contactChanged = isJSON(contact) ? JSON.parse(contact) : {};

    if (currentContact === "<p><br></p>") {
      delete contactChanged[currentLanguage];
    }
    else {
      contactChanged[currentLanguage] = convertForSaving(currentContact);
    }

    setContact(JSON.stringify(contactChanged));
  }, [currentContact])

  useEffect(() => {
    let imprintChanged = isJSON(imprint) ? JSON.parse(imprint) : {};

    if (currentImprint === "<p><br></p>") {
      delete imprintChanged[currentLanguage];
    }
    else {
      imprintChanged[currentLanguage] = convertForSaving(currentImprint);
    }

    setImprint(JSON.stringify(imprintChanged));
  }, [currentImprint])

  // Handles errors encountered in this component
  const errorHandler = (error) => {
    this.errorReason = i18n.t(`errorMessages.failedToCommunicate`);
    this.errorResponse = error.message;
    if (!showModalError) {
      toggleModalError();
    }
  };

  const handleCurrentLanguage = (e) => {
    setCurrentCompanyName(
      isJSON(companyName) ? JSON.parse(companyName)[e] : ""
    );
    setCurrentProvider(
      isJSON(provider) && JSON.parse(provider).hasOwnProperty(e)
        ? draftToHtml(JSON.parse(provider)[e])
        : ""
    );
    setCurrentPrivacy(
      isJSON(privacy) && JSON.parse(privacy).hasOwnProperty(e)
        ? draftToHtml(JSON.parse(privacy)[e])
        : ""
    );
    setCurrentContact(
      isJSON(contact) && JSON.parse(contact).hasOwnProperty(e)
        ? draftToHtml(JSON.parse(contact)[e])
        : ""
    );
    setCurrentImprint(
      isJSON(imprint) && JSON.parse(imprint).hasOwnProperty(e)
        ? draftToHtml(JSON.parse(imprint)[e])
        : ""
    );
    setCurrentLanguage(e);
  };

  const convertForSaving = (e) => {
    const blocksFromHTML = htmlToDraft(e);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    );
    return convertToRaw(state);
  }

  const handleSubmit = (e) => {
    let newCompanyInformation = {
      id: companyInformation.id,
      providerName: provider,
      companyName: companyName,
    };

    if (newCompanyInformation.id) {
      axios.clientService
        .put(`company-information`, newCompanyInformation)
        .then((response) => {
          // success message here
        })
        .catch((error) => {
          errorHandler(error);
        });
    } else {
      axios.clientService
        .post(`company-information`, newCompanyInformation)
        .then((response) => {
          // success message here
        })
        .catch((error) => {
          errorHandler(error);
        });
    }

    let newMiscInformation = {
      privacy: privacy,
      contact: contact,
      imprint: imprint,
    };

    axios.clientService
      .put(`misc-informations`, newMiscInformation)
      .then((response) => {
        toggleModal();
      })
      .catch((error) => {
        errorHandler(error);
      });


    const updatedInformation = initialInformation;

    // Translate the contact information
    try {
      let parser = JSON.parse(contact);
      updatedInformation.miscInformation.contact = parser[windowLanguage];
    } catch (e) {
      // Intentionaly blank, the value should only be changed if it's a valid JSON
    }
    // Translate the privacy information
    try {
      let parser = JSON.parse(privacy);
      updatedInformation.miscInformation.privacy = parser[windowLanguage];
    } catch (e) {
      // Intentionaly blank, the value should only be changed if it's a valid JSON
    }

    // Translate the imprint information
    try {
      let parser = JSON.parse(imprint);
      updatedInformation.miscInformation.imprint = parser[windowLanguage];
    } catch (e) {
      // Intentionaly blank, the value should only be changed if it's a valid JSON
    }

    // Translate the company name
    try {
      let parser = JSON.parse(
        companyName
      );
      updatedInformation.companyInformation.companyName =
        parser[windowLanguage];
    } catch (e) {
      // Intentionaly blank, the value should only be changed if it's a valid JSON
    }

    // Translate the provider name
    try {
      let parser = JSON.parse(
        provider
      );
      updatedInformation.companyInformation.providerName =
        parser[windowLanguage];
    } catch (e) {
      // Intentionaly blank, the value should only be changed if it's a valid JSON
    }

    //update store
    store.dispatch(setCompanyDetails(updatedInformation));
  };

  const buttons = [
    [
      "bold",
      "underline",
      "italic",
      "strike"
    ],
    [
      "subscript",
      "superscript",
    ],
    [
      "list",
      "indent",
      "outdent",
      "align",
    ],
    [
      "font",
      "fontSize",
    ],
    [
      "fontColor",
      "hiliteColor",
    ],
  ];

  const color = [
    "white",
    "black",
    "red",
    "orange",
    "yellow",
    "green",
    "blue",
    "indigo",
    "violet",
  ];

  const fontSize = [
    "8pt",
    "9pt",
    "10pt",
    "11pt",
    "12pt",
    "14pt",
    "16pt",
    "18pt",
    "24pt",
    "30pt",
    "36pt",
    "48pt",
    "60pt",
    "72pt",
    "96pt",
  ];
  const fontFamily = [
    "GT-Walsheim-Pro",
    "Arial",
    "Georgia",
    "Impact",
    "Tahoma",
    "Times New Roman",
    "Verdana",
  ];

  const options = {
    font: fontFamily,
    fontSize: fontSize,
    buttonList: buttons,
    fontColor: color,
    hiliteColor: color,
    height: "300px",
    width: "100%"
  }

  return (
    <Container>
      <AvForm onValidSubmit={handleSubmit}>
        <Card>
          <CardHeader>
            <h1>{i18n.t(`companyInformation.companyInformationSettings`)}</h1>
            {languages.map((answer) => {
              return (
                <>
                  <Button
                    color="primary"
                    onClick={() =>
                      handleCurrentLanguage(answer.toLocaleLowerCase())
                    }
                    disabled={answer.toLocaleLowerCase() === currentLanguage}
                  >
                    {answer}
                  </Button>{" "}
                </>
              );
            })}
          </CardHeader>
          <CardBody>
            <h2>{i18n.t(`companyInformation.generalCompanyInformation`)}</h2>
            <div class="container">
              <div class="row">
                <div class="col col-md-12">
                  {languages.map((answer) => {
                    if (isJSON(companyName)) {

                      let languageCheck = JSON.parse(companyName).hasOwnProperty(answer.toLocaleLowerCase());
                      return (
                        <>
                          {answer + ": "}
                          <FontAwesomeIcon
                            icon={languageCheck && JSON.parse(companyName)[answer.toLocaleLowerCase()].length > 0 ? faCheck : faTimes}
                            style={{ color: languageCheck && JSON.parse(companyName)[answer.toLocaleLowerCase()].length > 0 ? "green" : "red" }}
                          />{" "}
                        </>
                      );
                    }
                    return <>{answer + ": N/A "}</>;
                  })}
                  <h3>{i18n.t(`companyInformation.title`)}</h3>
                  <AvGroup>
                    <AvField
                      name="companyName"
                      type="text"
                      value={currentCompanyName}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "required",
                        },
                      }}
                      onChange={(e) => setCurrentCompanyName(e.target.value)}
                    />
                  </AvGroup>
                  {languages.map((answer) => {
                    if (isJSON(provider)) {
                      let languageCheck = JSON.parse(provider).hasOwnProperty(
                        answer.toLocaleLowerCase()
                      );
                      return (
                        <>
                          {answer + ": "}
                          <FontAwesomeIcon
                            icon={languageCheck ? faCheck : faTimes}
                            style={{ color: languageCheck ? "green" : "red" }}
                          />{" "}
                        </>
                      );
                    }
                    return <>{answer + ": N/A "}</>;
                  })}
                  <h3>{i18n.t(`companyInformation.titleParagraph`)}</h3>
                  <SunEditor
                    setOptions={options}
                    setContents={currentProvider}
                    onChange={(value) => { setCurrentProvider(value) }}
                  />
                </div>
              </div>
            </div>
            <br />
            <h2>{i18n.t(`companyInformation.miscCompanyInformation`)}</h2>
            <div class="container">
              <div class="row">
                <div class="col col-md-12">
                  {languages.map((answer) => {
                    if (isJSON(privacy)) {
                      let languageCheck = JSON.parse(privacy).hasOwnProperty(
                        answer.toLocaleLowerCase()
                      );
                      return (
                        <>
                          {answer + ": "}
                          <FontAwesomeIcon
                            icon={languageCheck ? faCheck : faTimes}
                            style={{ color: languageCheck ? "green" : "red" }}
                          />{" "}
                        </>
                      );
                    }
                    return <>{answer + ": N/A "}</>;
                  })}
                  <h3>{i18n.t(`footer.privacy`)}</h3>
                  <SunEditor
                    setOptions={options}
                    setContents={currentPrivacy}
                    onChange={(value) => { setCurrentPrivacy(value) }}
                  />

                  <br />
                  {languages.map((answer) => {
                    if (isJSON(contact)) {
                      let languageCheck = JSON.parse(contact).hasOwnProperty(
                        answer.toLocaleLowerCase()
                      );
                      return (
                        <>
                          {answer + ": "}
                          <FontAwesomeIcon
                            icon={languageCheck ? faCheck : faTimes}
                            style={{ color: languageCheck ? "green" : "red" }}
                          />{" "}
                        </>
                      );
                    }
                    return <>{answer + ": N/A "}</>;
                  })}
                  <h3>{i18n.t(`footer.contact`)}</h3>
                  <SunEditor
                    setContents={currentContact}
                    setOptions={options}
                    onChange={(value) => { setCurrentContact(value) }}
                  />
                  <br />
                  {languages.map((answer) => {
                    if (isJSON(imprint)) {
                      let languageCheck = JSON.parse(imprint).hasOwnProperty(
                        answer.toLocaleLowerCase()
                      );
                      return (
                        <>
                          {answer + ": "}
                          <FontAwesomeIcon
                            icon={languageCheck ? faCheck : faTimes}
                            style={{ color: languageCheck ? "green" : "red" }}
                          />{" "}
                        </>
                      );
                    }
                    return <>{answer + ": N/A "}</>;
                  })}
                  <h3>{i18n.t(`footer.imprint`)}</h3>
                  <SunEditor
                    setContents={currentImprint}
                    setOptions={options}
                    onChange={(value) => { setCurrentImprint(value) }}
                  />
                  <br />

                  <Button color="primary">
                    {i18n.t(`companyInformation.save`)}
                  </Button>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </AvForm>
      <ModalOK
        isOpen={showModal}
        onClose={toggleModal}
        modalTitle={""}
        modalBodyText={i18n.t(`companyInformation.savedChanges`)}
      />
    </Container>
  );
}
/**/
