import React, { useEffect, useState, useRef } from "react";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18n from "../../i18n";
import {
  Container,
  Card,
  CardHeader,
  CardBody,
  Label,
  Button,
} from "reactstrap";
import { useDispatch } from "react-redux";
import axios from "../../axios/Axios";
import {
  isEmpty,
  resetStyleSheet,
  LoadingIndicator,
} from "../../helpers/GenericHelper";
import ModalOK from "../../components/modals/ModalOK";
import ApiEnums from "../../enums/ApiEnums";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
/**
 * Commented out the imports for css values
 */
import { ChromePicker } from "react-color";
import { darkColor, primaryColor, textColor } from "../../helpers/Constants";

export default function FrontendSettings() {
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [captchaSiteKey, setCaptchaSiteKey] = useState("");
  const [captchaSecretKey, setCaptchaSecretKey] = useState("");
  const [captchaStatus, setCaptchaStatus] = useState(false);

  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [displayPrimaryColorPicker, setDisplayPrimaryColorPicker] =
    useState(false);
  const [displaySecondaryColorPicker, setDisplaySecondaryColorPicker] =
    useState(false);
  const [color, setColor] = useState("#4f2d7f");
  const [buttonColor, setButtonColor] = useState("#9581b2");
  const [buttonTextColor, setButtonTextColor] = useState("#fff");
  const dispatch = useDispatch();
  // Used to show the modal
  const [showModal, setToggleModal] = useState(false);
  const [showModalError, setToggleModalError] = useState("");

  const inputRef = useRef(null);

  // Toggles the Boolean that affects appearance of modal dialog box
  const toggleModal = () => {
    setToggleModal(!showModal);
  };

  // Toggles the Boolean that affects appearance of modal error box
  const toggleModalError = () => {
    setToggleModalError(!showModalError);
  };

  useEffect(() => {
    axios.clientService.get(`re-captcha-credentials-check`).then((response) => {
      if (!isEmpty(response)) {
        setCaptchaStatus(response.data.toString() == "true");
      }
    });
    /**
     * Commented out the getting css value to disable it
     */
    axios.clientService.get(`style/get-company-colors`).then((response) => {
      if (!isEmpty(response.data)) {
        setButtonColor(response.data[primaryColor]);
        setButtonTextColor(response.data[textColor]);
        setColor(response.data[darkColor]);
      }
    });
  }, []);

  const toggleDisplayColorPicker = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const toggleDisplayPrimaryColorPicker = () => {
    setDisplayPrimaryColorPicker(!displayPrimaryColorPicker);
  };

  const toggleDisplaySecondaryColorPicker = () => {
    setDisplaySecondaryColorPicker(!displaySecondaryColorPicker);
  };

  const closeDisplayColorPicker = () => {
    setDisplayColorPicker(false);
  };

  const closePrimaryDisplayColorPicker = () => {
    setDisplayPrimaryColorPicker(false);
  };

  const closeSecondaryDisplayColorPicker = () => {
    setDisplaySecondaryColorPicker(false);
  };

  const handleColorChange = (color) => {
    setColor(color.hex);
  };

  const handlePrimaryColorChange = (color) => {
    setButtonColor(color.hex);
  };

  const handleSecondaryColorChange = (color) => {
    setButtonTextColor(color.hex);
  };

  const imageHandleChange = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  const handleUpload = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("multipartFile", image.raw);

    const config = {
      headers: {
        contentType: "multipart/form-data",
      },
      responseType: "blob",
    };

    axios.clientService
      .post(`${ApiEnums.UploadController}/upload-logo`, formData, config)
      .then(() => {
        window.location.reload();
      })
      .catch((error) => { console.error(error) });
  };
  const captchaSiteKeyChange = (data) => {
    setCaptchaSiteKey(data.target.value);
  };
  const captchaSecretKeyChange = (data) => {
    setCaptchaSecretKey(data.target.value);
  };
  const handleCAPTCHA = () => {
    let captchaCredentials = {
      siteKey: captchaSiteKey,
      secretKey: captchaSecretKey,
    };
    axios.clientService
      .post(`re-captcha-credentials`, captchaCredentials)
      .then(() => {
        setCaptchaStatus(true);
      })
      .catch((error) => { console.error(error) });
  };
  const deleteCAPTCHA = () => {
    if (captchaStatus) {
      axios.clientService.delete(`re-captcha-credentials`).then((response) => {
        if (!isEmpty(response)) {
          setCaptchaStatus(false);
        }
      });
    }
  };

  const handleClick = () => {
    // 👇️ open file input box on click of other element
    inputRef.current.click();
  };

  // Handles errors encountered in this component
  const errorHandler = (error) => {
    this.errorReason = "";
    this.errorResponse = error.message;
    if (!showModalError) {
      toggleModalError();
    }
  };

  const savePrimaryColor = (e) => {
    let scssObject = {
      primary: buttonColor,
      buttonTextColor: buttonTextColor,
      dark: color,
    };

    axios.clientService
      .post(`style/save-company-colors`, scssObject)
      .then(() => {
        toggleModal();
        resetStyleSheet();
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  return (
    <Container fluid>
      <Card>
        <CardHeader style={{ paddingBottom: "0px" }}>
          <h1>{i18n.t(`settings.frontendSettings`)}</h1>
        </CardHeader>
        <CardBody style={{ paddingTop: "0px" }}>
          <React.Fragment>
            <div style={{ display: "flex" }}>
              {" "}
              {/* For the CAPTCHA */}
              <div className="mt-4" style={{ width: "33.33%" }}>
                <div>
                  <Label className="text-left">
                    <h2>{i18n.t(`settings.CAPTCHA`)}</h2>
                  </Label>
                </div>
                <div>
                  {i18n.t(`settings.statusCAPTCHA`) + ": "}
                  <FontAwesomeIcon
                    icon={captchaStatus ? faCheck : faTimes}
                    style={{ color: captchaStatus ? "green" : "red" }}
                  />
                </div>
                <br />
                <AvForm>
                  {i18n.t(`settings.captchaSecretKey`) + ":"}
                  <AvGroup>
                    <AvField
                      name="captchaSecretKey"
                      placeholder={
                        captchaStatus
                          ? "******************************"
                          : i18n.t(`settings.captchaSecretKey`)
                      }
                      type="text"
                      onChange={captchaSecretKeyChange}
                      validate={{
                        required: {
                          value: true,
                        },
                      }}
                    />
                  </AvGroup>
                  {i18n.t(`settings.captchaSiteKey`) + ":"}
                  <AvGroup>
                    <AvField
                      name="captchaSiteKey"
                      placeholder={
                        captchaStatus
                          ? "******************************"
                          : i18n.t(`settings.captchaSiteKey`)
                      }
                      type="text"
                      onChange={captchaSiteKeyChange}
                      validate={{
                        required: {
                          value: true,
                        },
                      }}
                    />
                  </AvGroup>
                </AvForm>
                <div className="mt-3">
                  <Button color="primary" onClick={handleCAPTCHA}>
                    {i18n.t(`commonButtonTexts.save`)}
                  </Button>{" "}
                  <Button
                    color="danger"
                    onClick={deleteCAPTCHA}
                    disabled={!captchaStatus}
                  >
                    {i18n.t(`settings.wipeCAPTCHA`)}
                  </Button>
                </div>
                {/*
            <AvForm onValidSubmit={handleCAPTCHA}>
              <div className="text-center mt-3">
                <Button color="primary" size="lg" id="signIn">
                  <FontAwesomeIcon icon={faSignInAlt} />{" "}
                  <span id="signInText">
                    {this.translation(`signIn.signInButtonText`)}
                  </span>
                </Button>
                <Button color="primary" onClick={handleCAPTCHA}>{i18n.t(`commonButtonTexts.save`)}</Button>
                {" "}
                <Button color="danger" onClick={deleteCAPTCHA} disabled={!captchaStatus}>{i18n.t(`settings.wipeCAPTCHA`)}</Button>
              </div>
            </AvForm>
            */}
              </div>
              {/* For the Logo */}
              <div
                className="mt-4"
                style={{ width: "33.33%", textAlign: "center" }}
              >
                {image.preview ? (
                  <label style={{ cursor: "pointer" }}>
                    <div>
                      <Label className="text-left">
                        <h2>{i18n.t(`settings.logo`)}</h2>
                      </Label>
                    </div>
                    <div>
                      <img
                        src={image.preview}
                        alt={i18n.t(`settings.logoMissing`)}
                        className="img-fluid"
                        style={{
                          backgroundSize: "100%",
                          height: "175px",
                          backgroundColor: "white",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                        }}
                      />
                    </div>
                  </label>
                ) : (
                  <>
                    <label
                      style={{ cursor: "pointer" }}
                      className="text-center"
                    >
                      <span className="fa-stack fa-2x mt-3 mb-2">
                        <FontAwesomeIcon icon={faCamera} />
                      </span>
                      <h5 className="text-center">
                        {i18n.t(`settings.selectLogo`)}
                      </h5>
                    </label>
                  </>
                )}
                <div>
                  <LoadingIndicator />
                  <input
                    type="file"
                    style={{ display: "none" }}
                    accept="image/*"
                    ref={inputRef}
                    onChange={imageHandleChange}
                  />
                  <Button onClick={handleClick} color="primary">
                    {i18n.t(`commonButtonTexts.chooseImage`)}
                  </Button>
                  {"  "}
                  <Button
                    color="primary"
                    onClick={handleUpload}
                    disabled={image.raw ? false : true}
                  >
                    {i18n.t(`commonButtonTexts.upload`)}
                  </Button>
                </div>
              </div>{" "}
              <div className="mt-4" style={{ width: "33.33%" }}>
                <div>
                  <Label className="text-left">
                    <h2>{i18n.t(`settings.companyColors`)}</h2>
                  </Label>
                </div>
                <Label>{i18n.t(`settings.backgroundColor`)}</Label>
                <div>
                  <div className="swatch" onClick={toggleDisplayColorPicker}>
                    <div
                      style={{ backgroundColor: color }}
                      className="colorPicker"
                    />
                  </div>
                  {displayColorPicker ? (
                    <div className="pickerPopover">
                      <div
                        className="pickerPopover"
                        onClick={closeDisplayColorPicker}
                      />
                      <ChromePicker
                        color={color}
                        onChangeComplete={handleColorChange}
                        disableAlpha={true}
                      />
                    </div>
                  ) : null}
                </div>

                <Label>{i18n.t(`settings.buttonColor`)}</Label>
                <div>
                  <div
                    className="swatch"
                    onClick={toggleDisplayPrimaryColorPicker}
                  >
                    <div
                      style={{ backgroundColor: buttonColor }}
                      className="colorPicker"
                    />
                  </div>
                  {displayPrimaryColorPicker ? (
                    <div className="pickerPopover">
                      <div
                        className="pickerPopOverCover"
                        onClick={closePrimaryDisplayColorPicker}
                      />
                      <ChromePicker
                        color={buttonColor}
                        onChangeComplete={handlePrimaryColorChange}
                        disableAlpha={true}
                      />
                    </div>
                  ) : null}
                </div>

                <Label>{i18n.t(`settings.buttonTextColor`)}</Label>
                <div>
                  <div
                    className="swatch"
                    onClick={toggleDisplaySecondaryColorPicker}
                  >
                    <div
                      style={{ backgroundColor: buttonTextColor }}
                      className="colorPicker"
                    />
                  </div>
                  {displaySecondaryColorPicker ? (
                    <div className="pickerPopover">
                      <div
                        className="pickerPopOverCover"
                        onClick={closeSecondaryDisplayColorPicker}
                      />
                      <ChromePicker
                        color={buttonTextColor}
                        onChangeComplete={handleSecondaryColorChange}
                        disableAlpha={true}
                      />
                    </div>
                  ) : null}
                </div>
                <div>
                  <Button color="primary" onClick={savePrimaryColor}>
                    {i18n.t(`commonButtonTexts.save`)}
                  </Button>
                </div>
              </div>
            </div>
          </React.Fragment>
        </CardBody>
      </Card>
      <ModalOK
        isOpen={showModal}
        onClose={toggleModal}
        modalTitle={""}
        modalBodyText={i18n.t(`companyInformation.savedChanges`)}
        size="xl"
      />
    </Container>
  );
}
