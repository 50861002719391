import {
  faHome,
  faSignInAlt,
  faSuitcase,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
import async from "../components/Async";
import i18n from "../i18n";
// Auth
import SignIn from "../pages/auth/SignIn";
import SignOut from "../pages/auth/SignOut";
import SignUp from "../pages/auth/SignUp";
import ChangePassword from "../pages/auth/ChangePassword";
// Cases
import CaseDetailsPage from "../pages/cases/CaseDetailsPage";
//Messages
import NewMessagePage from "../pages/messages/NewMessagePage";
// Misc
import Blank from "../pages/misc/Blank";

// Admin panel
import FrontendSettings from "../pages/admin/FrontendSettings";
import CompanyInformationSettings from "../pages/admin/CompanyInformationSettings";

import RoleEnum from "../enums/RoleEnums";

// Dashboards
const Default = async(() => import("../pages/dashboards/Default"));

// Routes
const dashboardRoutes = {
  path: "/",
  name: "Dashboard",
  header: "Main",
  icon: faHome,
  component: Default,
  children: null,
  isProtected: true,
  roles: [RoleEnum.USER],
};

const signInRoute = {
  path: "/sign-in",
  name: "Sigh In",
  component: SignIn,
};

const signUpRoute = {
  path: "/sign-up",
  component: SignUp,
};

const caseRoutes = {
  name: i18n.t(`commonText.cases`),
  header: i18n.t(`routeNames.mainMenu`),
  path: "/",
  icon: faSuitcase,
  isProtected: true,
  roles: [RoleEnum.USER],
};

const casePrivateRoutes = {
  path: "cases",
  name: "Cases",
  isProtected: true,
  roles: [RoleEnum.USER],
  children: [
    {
      path: "/cases/case-details/:id",
      component: CaseDetailsPage,
    },
    {
      path: "/cases/case-details/:id/new-message",
      component: NewMessagePage,
    },
  ],
};

const authRoutes = {
  path: "/auth",
  name: i18n.t(`routeNames.account`),
  icon: faSignInAlt,
  children: [
    {
      path: "/auth/changePassword",
      name: i18n.t(`routeNames.changePassword`),
      component: ChangePassword,
    },
    {
      path: "/auth/sign-out",
      name: i18n.t(`routeNames.signOut`),
      component: SignOut,
    },
  ],
};

const adminSettingsRoute = {
  path: "/settings",
  name: i18n.t(`routeNames.settings`),
  isProtected: true,
  roles: [RoleEnum.ADMIN],
  icon: faCog,
  children: [
    {
      path: "/settings/frontend",
      name: i18n.t(`settings.frontend`),
      component: FrontendSettings,
    },
    {
      path: "/settings/company-information",
      name: i18n.t(`settings.companyInformation`),
      component: CompanyInformationSettings,
    },
  ],
};
// This route is not visisble in the sidebar
const privateRoutes = {
  path: "/private",
  name: "Private",
  children: [
    {
      path: "/private/blank",
      name: "Blank Page",
      component: Blank,
    },
  ],
};

// Dashboard specific routes
export const dashboard = [
  dashboardRoutes,
  privateRoutes,
  casePrivateRoutes,
  caseRoutes,
  adminSettingsRoute,
  authRoutes,
];

// Auth specific routes
export const page = [authRoutes, signInRoute, signUpRoute];

// accesible to both authenticated and not with different layouts
// This is to be removed at another time as this item is still referenced by many other items, clean up to be done in the future
export const bothLayoutPage = [];

// All routes
const routes = [caseRoutes, adminSettingsRoute, authRoutes];
export default routes;
