import React, { useEffect } from "react";
import { Container, Card } from "reactstrap";
import AuthLanguage from "../components/auth/AuthLanguage";
import Footer from "../components/Footer";
import Main from "../components/Main";

function Auth(props) {
  return (
    <React.Fragment>
      <AuthLanguage />
      <div className="background-sign-in"></div>
      <Main>
        <Container
          className="h-100"
          style={{ width: "100%", maxWidth: "100%", padding: "15px" }}
        >
          {props.children}
        </Container>
      </Main>
      <Card className="footer-auth" style={{ marginBottom: "0px" }}>
        <Footer />
      </Card>
    </React.Fragment>
  );
}

export default Auth;
