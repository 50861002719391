import {
  faEdit,
  faFileDownload,
  faPlusSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import draftToHtml from "draftjs-to-html";
import React from "react";
import { withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import Axios from "../../axios/Axios";
import FadeAlert from "../../components/FadeAlert";
import ModalDelete from "../../components/modals/ModalDelete";
import ModalError from "../../components/modals/ModalError";
import ApiEnums from "../../enums/ApiEnums";
import MessageStatusEnum from "../../enums/MessageStatusEnum";
import {
  formatDate,
  isEmpty,
  LoadingIndicator,
} from "../../helpers/GenericHelper";
import { store } from "../../redux/store/index";

/*
 * Class for displaying a list of messages
 */
class MessageList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pagesCount: null,
      paginatedMessageTableData: [],

      showModalDelete: false,
      showModalError: false,

      currentPage: 0,
    };
    this.props.onRef && this.props.onRef(this);
    this.id = this.props.id;
    this.parentPage = this.props.parentPage;

    this.translation = this.props.t;
    this.firstIndex = 0;

    this.mainError = "";
    this.errorReason = "";
    this.errorResponse = "";

    this.modalBodyText = "";
    this.modalTitle = "";
    this.modalDeleteEvent = null;

    this.toggleModalDelete = this.toggleModalDelete.bind(this);
    this.toggleModalError = this.toggleModalError.bind(this);
    this.setTableData = this.setTableData.bind(this);
    this.errorHandler = this.errorHandler.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.downloadAttachment = this.downloadAttachment.bind(this);
    this.editDraft = this.editDraft.bind(this);
    this.deleteDraft = this.deleteDraft.bind(this);
  }

  // Commands to execute when the component is mounting.
  componentDidMount() {
    // Clears the location state
    window.history.replaceState(null, "");
    this.setTableData();
  }

  componentDidUpdate(prevProps) {
    if(prevProps.messages !== this.props.messages){
      this.setTableData();
    }
  }

  // Toggles the Boolean that affects the appearance of the deletion modal.
  toggleModalDelete() {
    this.setState({
      showModalDelete: !this.state.showModalDelete,
    });
  }

  // Toggles the Boolean that affects appearance of modal error dialog box
  toggleModalError() {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  }
  // Table Data for the message list is set
  setTableData() {
   this.setState({
    paginatedMessageTableData: this.props.messages
   })
  }

  // Catches the errors encountered within this page
  errorHandler(currentOperation, error) {
    if (currentOperation === "Getting Messages") {
      this.mainError = this.translation(`errorMessages.messageListLoadError`);
    } else if (currentOperation === "Download Attachment") {
      this.mainError = this.translation(
        `errorMessages.messageAttachmentDownloadError`
      );
    } else if (currentOperation === "Deleting drafts") {
      this.mainError = this.translation(`errorMessages.draftDeletionError`);
    }
    if (error?.response?.data?.errorKey === "userunauthorized") {
      this.errorReason = this.translation(`errorMessages.accessForbidden`);
    } else {
      this.errorReason = this.translation(`errorMessages.failedToCommunicate`);
    }
    this.errorResponse = error.message;
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  }

  // Function for downloading document
  downloadAttachment(id, filename) {
    this.setState({ downloading: true });
    const config = {
      responseType: "blob",
    };

    Axios.clientService
      .get(`${ApiEnums.Document}/` + id + `/` + filename, config)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.setState({ downloading: false });
      })
      .catch((error) => {
        this.errorHandler("Download Attachment", error);
      });
  }

  // Edit the draft
  editDraft(message) {
    this.props.history.push({
      pathname: `/cases/case-details/${this.props.match.params.id}/new-message`,
      state: {
        draftMessage: message,
      },
    });
  }

  // Delete the draft
  deleteDraft(message) {
    this.modalTitle = this.translation(`messageList.deleteDraft`);
    this.modalBodyText = this.translation(`messageList.deleteDraftMessage`);
    this.modalDeleteEvent = async () => {
      Axios.clientService
        .delete(`${ApiEnums.Message}/${message.id}`)
        .then(() => {
          let newData = this.state.paginatedMessageTableData.filter((data) => data.id !== message.id)
          this.setState({
            paginatedMessageTableData: newData
          })
        })
        .catch((error) => {
          this.errorHandler("Deleting drafts", error);
        });
    };

    this.toggleModalDelete();
  }
  // Handles the changing of pages
  handlePageChange(e, index) {
    e.preventDefault();
    this.setState({ currentPage: index }, () => {
      this.setTableData();
    });
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <Card>
          <CardHeader>
            {this.props.location.state?.messageSuccessMessage && (
              <FadeAlert color="success">
                {this.props.location.state.messageSuccessMessage}
              </FadeAlert>
            )}
            <CardTitle>
              <h1>
                {this.parentPage === "Case Details"
                  ? this.translation(`messageList.messageList`)
                  : this.translation(`messageList.previousMessages`)}
              </h1>
            </CardTitle>
            {this.parentPage === "Case Details" && (
              <div className="card-actions float-right">
                <Link to={`/cases/case-details/${this.id}/new-message`}>
                  <Button color="primary" id="newMessageButton" size="m">
                    <FontAwesomeIcon icon={faPlusSquare} />{" "}
                    {this.translation(`messageList.sendNewMessage`)}
                  </Button>
                  <UncontrolledTooltip target="newMessageButton">
                    {this.translation(`messageList.sendNewMessage`)}
                  </UncontrolledTooltip>
                </Link>
              </div>
            )}
          </CardHeader>
          <CardBody>
            <Row className="justify-content-center">
              {this.state.downloading ? (
                <>
                  <LoadingIndicator />
                  <h5>
                    {this.translation(`messageList.downloadingAttachment`)}
                  </h5>
                </>
              ) : (
                <LoadingIndicator />
              )}
            </Row>
            <Table
              responsive
              className="my-0 message-list"
              style={{ tableLayout: "fixed", width: "100%" }}
            >
              {!isEmpty(this.state.paginatedMessageTableData) ? (
                this.state.paginatedMessageTableData?.map((messages, i) => (
                  <tbody>
                    <tr key={`entity-${i}`}>
                      <td
                        style={{ verticalAlign: "top" }}
                      >
                        <div
                          align="justify"
                          dangerouslySetInnerHTML={{
                            __html: draftToHtml(JSON.parse(messages.message)),
                          }}
                        />
                      </td>
                      <td
                        style={{
                          width: "220px",
                          verticalAlign: "top",
                          padding: "0",
                        }}
                      >
                        <Table
                          style={{
                            backgroundColor: "#f8f9fa",
                            textAlign: "right",
                          }}
                        >
                          <tr>
                            <td
                              style={{ borderTop: "none" }}
                            >
                              {!isEmpty(messages.messageStatuses)
                                ? formatDate(
                                    messages.messageStatuses[
                                      messages.messageStatuses.length - 1
                                    ].dateTime,
                                    true
                                  )
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ borderTop: "none" }}
                            >
                              {!isEmpty(messages.messageStatuses)
                                ? this.translation(
                                    `enumTranslation.${messages.messageStatuses[
                                      messages.messageStatuses.length - 1
                                    ].statusOfMessage.toLowerCase()}`
                                  )
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ borderTop: "none" }}
                            >
                              {messages.messageStatuses[
                                messages.messageStatuses?.length - 1
                              ]?.statusOfMessage === MessageStatusEnum.DRAFT &&
                                this.parentPage !== "New Messages" && (
                                  <div>
                                    <Button
                                      color="primary"
                                      id={`editChangeStatusButton-${messages.id}`}
                                      onClick={this.editDraft.bind(
                                        this,
                                        messages
                                      )}
                                    >
                                      <FontAwesomeIcon icon={faEdit} />
                                    </Button>
                                    <UncontrolledTooltip
                                      placement="right"
                                      target={`editChangeStatusButton-${messages.id}`}
                                    >
                                      {this.translation(
                                        `commonButtonTexts.edit`
                                      )}
                                    </UncontrolledTooltip>
                                    <span> </span>
                                    <Button
                                      color="primary"
                                      onClick={this.deleteDraft.bind(
                                        this,
                                        messages
                                      )}
                                    >
                                      <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                  </div>
                                )}
                            </td>
                          </tr>
                        </Table>
                      </td>
                    </tr>
                    <tr>
                      {this.parentPage !== "New Messages" ? (
                        <Table responsive striped className="my-0">
                          {!isEmpty(messages.documents) ? (
                            <tr key={`entity-${i}`}>
                              <td
                                style={{
                                  borderTop: "none",
                                }}
                              >
                                {messages.documents.map((docs, i) => (
                                  <Button
                                    color="primary"
                                    onClick={() =>
                                      this.downloadAttachment(
                                        docs.id,
                                        docs.fileName
                                      )
                                    }
                                    style={{ marginLeft: "5px" }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faFileDownload}
                                      style={{ marginRight: "15px" }}
                                    />
                                    {docs.fileName}
                                  </Button>
                                ))}
                              </td>
                            </tr>
                          ) : null}
                        </Table>
                      ) : (
                        <td></td>
                      )}
                    </tr>
                  </tbody>
                ))
              ) : (
                <tr>
                  <td className="text-center" colSpan="4">
                    {this.translation(`messageList.noMessagesSentOrReceived`)}
                  </td>
                </tr>
              )}
            </Table>
            <br />
          </CardBody>
        </Card>
        <ModalDelete
          isOpen={this.state.showModalDelete}
          onClose={this.toggleModalDelete}
          event={this.modalDeleteEvent}
          modalTitle={this.modalTitle}
          modalBodyText={this.modalBodyText}
        ></ModalDelete>
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.mainError}
          errorReason={this.errorReason}
          errorResponse={this.errorResponse}
          modalTitle="Error"
        ></ModalError>
      </>
    );
  }
}

export default withRouter(withTranslation()(MessageList));
