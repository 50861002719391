import {
  faEye,
  faEyeSlash,
  faSignInAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
import Cookies from "js-cookie";
import React from "react";
import {
  CustomView,
  isChrome,
  isChromium,
  isEdge,
  isFirefox,
  isSafari,
} from "react-device-detect";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import "../../app.css";
import Axios from "../../axios/Axios";
import sessionStorage from "../../axios/SessionStorage";
import localStorage from "../../axios/LocalStorage";
import FadeAlert from "../../components/FadeAlert";
import ApiEnums from "../../enums/ApiEnums";
import BrowserEnums from "../../enums/BrowserEnums";
import { generateBrowserLink, isEmpty } from "../../helpers/GenericHelper";
import {
  resetAccountDetails,
  setAccountDetails,
} from "../../redux/actions/AccountActions";
import { persistor, store } from "../../redux/store/index";
import ModalOK from "../../components/modals/ModalOK";
import draftToHtml from "draftjs-to-html";
import { language } from "../../helpers/Constants";
import { isHttps } from "../..";

// Maps dispatch to props so that account details are stored and are accessible within the app
const mapDispatchToProps = (dispatch) => ({
  SetAccountDetails: (userData) => {
    dispatch(setAccountDetails(userData));
  },
  ResetAccountDetails: (userData) => {
    dispatch(resetAccountDetails(userData));
  },
  dispatch,
});

/*
 * Class that handles sign in of users of the client dashboard
 */
class Signin extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      errorMessage: "",
      rememberMe: false,
      showPassword: false,
      companyName: "",
      providerName: "",
      languages: [],
      showModal: false,
      modalForm: null,
    };

    this.browserList = Object.values(BrowserEnums);
    this.translation = this.props.t;
    this.firstIndex = 0;

    // binds
    this.handleUsernameOnchange = this.handleUsernameOnchange.bind(this);
    this.handlePasswordOnchange = this.handlePasswordOnchange.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.showHidePassword = this.showHidePassword.bind(this);
    this.errorHandler = this.errorHandler.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.account.company !== undefined) {
        // NOTE: companyName = Header, providerName = Body
        this.setState({
          companyName:
            this.props.account.company.companyInformation?.companyName,
          providerName:
            this.props.account.company.companyInformation?.providerName,
          languages: this.props.account.company?.languages,
        });
      }
    }
  }
  loadModalPrivacy = () => {
    const misc = store.getState().account?.company?.miscInformation ?? null;
    this.modalTitle = this.translation("footer.privacy");
    this.modalBodyText = draftToHtml(misc["privacy"]);
    this.setState({
      modalForm: (
        <div
          dangerouslySetInnerHTML={{ __html: draftToHtml(misc["privacy"]) }}
        />
      ),
    });
    this.toggleModal();
  };

  // Loads the modal
  loadModalText = (item) => {
    let intoText = "";
    this.modalTitle = this.translation(`signIn.loginInfo1`, {
      companyName: this.state.companyName,
    });
    this.modalBodyText = intoText;
    this.setState({
      modalForm: (
        <>
          <h2>{this.state.companyName}</h2>
          <p>
            <div
              dangerouslySetInnerHTML={{
                __html: draftToHtml(this.state.providerName),
              }}
            />
          </p>
        </>
      ),
    });
    this.toggleModal();
  };

  // Toggles the Boolean that affects appearance of modal dialog box
  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  // Commands to execute when the component is mounting.
  componentDidMount() {
    this._isMounted = true;
    if (this.props.account.company !== undefined) {
      if (this._isMounted) {
        this.setState({
          companyName: this.props.account.company.companyInformation?.companyName,
          providerName:
            this.props.account.company.companyInformation?.providerName,
          languages: this.props.account.company?.languages,
        });
      }
    }

    // redirect to home if already logged
    if (
      sessionStorage.getAccessToken() &&
      sessionStorage.getAccessToken() !== ""
    ) {
      if (this._isMounted) {
        this.props.history.push("/");
      }
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  // Handles the changes to the username field
  handleUsernameOnchange(event) {
    if (this._isMounted) {
      this.setState({ username: event.target.value.toLowerCase() });
    }
  }

  // Handles the changes to the password field
  handlePasswordOnchange(event) {
    if (this._isMounted) {
      this.setState({ password: event.target.value });
    }
  }

  // Handles submission of existing user to allow access to the app
  async handleValidSubmit() {
    if (this._isMounted) {
      document.getElementById("signInText").innerHTML = this.translation(`signIn.signingInButtonText`);

      document.getElementById("signIn").disabled = true;
      let reload = false;
      // User is authenticated first
      await Axios.account
        .post(ApiEnums.Authenticate, {
          username: this.state.username,
          password: this.state.password
        })
        .then((response) => {
          if (response.data && response.data?.id_token) {
            sessionStorage.setToken(response.data.id_token);
            localStorage.setToken(response.data.id_token);

          }
        })
        .catch((error) => {
          this.errorHandler(error);
        });

      // The first name of the user is retrieved
      await Axios.account
        .get(`account`)
        .then((userResponse) => {
          let userData = userResponse.data;
          if (this._isMounted) {
            if (userResponse.data?.lastLanguage !== null) {
              Cookies.set(
                language,
                userResponse.data?.lastLanguage.toLowerCase(),
                { secure: isHttps, sameSite: 'lax' }
              );
            }

            this.props.dispatch(setAccountDetails(userData));
            reload = true;
          }
        })
        .catch((error) => {
          sessionStorage.clearToken();
          localStorage.clearToken();
          persistor.purge();
        });
      if (reload) {
        window.location.reload("/");
      }
    }
  }

  // Either shows or hides the contents of the password field/s
  showHidePassword() {
    if (this._isMounted) {
      this.setState({ showPassword: !this.state.showPassword });
    }
  }

  // Handles errors in this page
  errorHandler(error) {
    document.getElementById("signInText").innerHTML = this.translation(
      `signIn.signInButtonText`
    );
    document.getElementById("signIn").disabled = false;

    let errorMessage = "";
    if (error.response.data.status == 401 || error.response.data.message == "error.validation") {
      errorMessage = this.translation(`errorMessages.incorrectLogin`);
    } else if (error.message === "Network Error") {
      errorMessage = this.translation(`errorMessages.networkError`);
    } else {
      errorMessage = this.translation(`errorMessages.internalServerError`);
    }

    this.setState({
      errorMessage: errorMessage,
    });

    setTimeout(() => {
      this.setState({
        errorMessage: "",
      });
    }, 5000);
  }

  render() {
    return (
      <React.Fragment>
        <div className="sign-in-area-outer">
          <Row className="sign-in-area-inner">
            <Col>
              <div className="sign-in-box">
                <div className="text-center mt-4">
                  <p className="lead">{this.translation(`signIn.signIn`)}</p>
                </div>

                <Card className="sign-in-card" style={{ zIndex: "0" }}>
                  <div>
                    <CardHeader>
                      {this.props.location.state?.successMessage && (
                        <FadeAlert color="success">
                          {this.props.location.state.successMessage}
                        </FadeAlert>
                      )}
                      {this.state.errorMessage !== "" && (
                        <FadeAlert color="danger">
                          {this.state.errorMessage}
                        </FadeAlert>
                      )}
                      <div
                        className="compactor-box"
                        style={{
                          padding: "10px",
                        }}
                      >
                        <div
                          style={{
                            backgroundImage: `url('/api/upload-controller/get-logo')`,
                            backgroundSize: "contain",
                            height: "100px",
                            backgroundColor: "white",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                          }}
                        />
                      </div>
                    </CardHeader>
                    <CardBody className="sign-in-card-body">
                      <div className="m-sm-4">
                        <AvForm onValidSubmit={this.handleValidSubmit}>
                          <AvGroup>
                            <AvField
                              name="username"
                              placeholder={this.translation(
                                `fieldLabels.username`
                              )}
                              type="text"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: this.translation(
                                    `fieldWarnings.enterUsername`
                                  ),
                                },
                              }}
                              onChange={this.handleUsernameOnchange}
                            />
                          </AvGroup>
                          <AvGroup className="passwordField">
                            <AvField
                              name="password"
                              placeholder={this.translation(
                                `fieldLabels.password`
                              )}
                              type={
                                this.state.showPassword ? "text" : "password"
                              }
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: this.translation(`fieldWarnings.enterPassword`),
                                },
                              }}
                              onChange={this.handlePasswordOnchange}
                            />
                            <FontAwesomeIcon
                              icon={
                                this.state.showPassword ? faEyeSlash : faEye
                              }
                              onClick={this.showHidePassword}
                              className="eyeIcon"
                            />
                          </AvGroup>
                          <div className="text-center mt-3">
                            <Button color="primary" size="lg" id="signIn">
                              <FontAwesomeIcon icon={faSignInAlt} />{" "}
                              <span id="signInText">
                                {this.translation(`signIn.signInButtonText`)}
                              </span>
                            </Button>
                          </div>
                        </AvForm>
                        <br />
                        <Link to={"/sign-up"} style={{ textAlign: "center" }}>
                          <p>{this.translation(`signIn.noAccountSignUp`)}</p>
                        </Link>

                        <div className="text-center">
                          <a
                            to="/"
                            type="button"
                            className="btn btn-link privacy-policy"
                            onClick={() => this.loadModalPrivacy()}
                          >
                            <u style={{ cursor: "pointer" }}>{this.translation(`privacy.viewPrivacy`)}</u>
                          </a>
                        </div>
                      </div>
                    </CardBody>
                  </div>
                </Card>
              </div>
            </Col>
            <Col>
              <div className="sign-in-text-box">
                <div className="regular-box">
                  {/* REGULAR */}
                  <h2>{this.state.companyName}</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: draftToHtml(this.state.providerName),
                    }}
                  />
                </div>

                <CustomView
                  condition={
                    !isEdge && !isChrome && !isChromium && isFirefox && isSafari
                  }
                >
                  <small>
                    <div>
                      <p>{this.translation("signIn.browserWarning1")}</p>
                      <ul>
                        {this.browserList.map((browser) => (
                          <li key={browser}>
                            <a
                              href={generateBrowserLink(browser)}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {browser}
                            </a>
                          </li>
                        ))}
                      </ul>
                      <p>{this.translation("signIn.browserWarning2")}</p>
                    </div>
                  </small>
                </CustomView>
              </div>
            </Col>
          </Row>
        </div>
        <ModalOK
          isOpen={this.state.showModal}
          onClose={this.toggleModal}
          modalTitle={this.modalTitle}
          modalBodyText={this.state.modalForm}
          size="xl"
        />
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(
    (store) => ({
      account: store.account,
    }),
    mapDispatchToProps
  )(withTranslation()(Signin))
);
