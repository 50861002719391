// List that contains values that used to set number of records per page for data table
export const recordsPerPage = [
  {
    value: 20,
    label: 20,
  },
  {
    value: 50,
    label: 50,
  },
  {
    value: 100,
    label: 100,
  },
  {
    value: 200,
    label: 200,
  },
];

//Export host URL for languages
export var language = window.location.host;

export const darkColor = "--dark";
export const primaryColor = "--primary";
export const textColor = "--buttonTextColor";
