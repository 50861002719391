import React, { useState } from "react";
import CookieConsent from "react-cookie-consent";
import i18n from "../i18n";
import ModalOK from "../components/modals/ModalOK";
import draftToHtml from "draftjs-to-html";
import { store } from "../redux/store/index";

function CookieBanner() {
  const [modalToggle, setModalToggle] = useState(false);
  const [modalBody, setModalBody] = useState(false);

  function handleModalToggle() {
    const misc = store.getState().account?.company?.miscInformation ?? null;
    setModalBody(draftToHtml(misc["privacy"]));
    setModalToggle(!modalToggle);
  }

  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText={i18n.t(`commonButtonTexts.okay`)}
        cookieName="cookieConsent"
        style={{
          boxShadow: "0 -3px 4px rgba(126, 142, 177, 0.12)",
          background: "white",
          color: "black",
        }}
        buttonStyle={{
          background: "",
          border: "",
          borderRadius: "",
          boxShadow: "",
          color: "",
          cursor: "",
          flex: "0 0 auto",
          padding: "5px 10px",
          margin: "5px",
        }}
        buttonClasses="btn btn-primary"
        expires={150}
      >
        <div>
          {i18n.t(`cookie.info`)}{' '}
          <a
            className="btn-link"
            onClick={handleModalToggle}
          >
            <u style={{cursor:"pointer"}}>{i18n.t(`cookie.privacyMoreInfo`)}</u>
          </a>
        </div>
      </CookieConsent>
      <ModalOK
        isOpen={modalToggle}
        onClose={handleModalToggle}
        modalTitle={i18n.t("footer.privacy")}
        modalBodyText={
          <div dangerouslySetInnerHTML={{ __html: modalBody }} />
        }
        size="xl"
      />
    </>
  );
}

export default CookieBanner;
