import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import App from "./App";
import i18n from "./i18n";
import Cookies from "js-cookie";
import axios from "./axios/Axios";
import { isEmpty } from "./helpers/GenericHelper";
import { language } from "./helpers/Constants";
import { isArrayOrNodeList } from "reactstrap/lib/utils";

export const isHttps = location.protocol === "https:";

/**
 * Function to set the default language if no cookie is set.
 * This function needs to run before anything else!
 */
async function setDefaultLanguage() {
  let cookieLanguage = Cookies.get(language);
  if (cookieLanguage === null || cookieLanguage === undefined) {
    await axios.clientService.get("company-information").then((response) => {
      if (!isEmpty(response.data) && isArrayOrNodeList(response.data.languages) && !isEmpty(response.data.languages)) {
        let defaultLanguage = response.data.languages[0].toLowerCase();
        Cookies.set(
          language,
          defaultLanguage,
          { secure: isHttps, sameSite: 'lax' }
        );
        i18n.changeLanguage(defaultLanguage)
      }
    })
  }
}


setDefaultLanguage();

// Render app in `#root` element
ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>,
  document.getElementById("root")
);
