import Cookies from "js-cookie";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button } from "reactstrap";
import { isHttps } from "../..";
import "../../app.css";
import { language } from "../../helpers/Constants";
import { getBrowserLanguage } from "../../helpers/GenericHelper";

/**
 * A class for changing the language within the auth pages of the client dashboard
 */
class AuthLanguage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languages: [],
    };

    this.changeLanguage = this.changeLanguage.bind(this);
    this.getLanguages = this.getLanguages.bind(this);
    this.isLanguageSelected = this.isLanguageSelected.bind(this);
  }

  componentDidMount() {
    this.checkLanguageCookie(this.props.i18n.language);
    this.getLanguages();
  }

  componentDidUpdate(prevProps) {
    this.checkLanguageCookie(Cookies.get(language));
    if (prevProps !== this.props) {
      this.getLanguages();
    }
  }

  checkLanguageCookie(currentLanguage) {
    // Set language cookies depending on currentlangauge
    if (Cookies.get(language) != currentLanguage) {
      Cookies.set(language, currentLanguage, { secure: isHttps, sameSite: 'lax' });
      window.location.reload();
    }
  }

  isLanguageSelected(languageString) {
    let currentLanguage =
      Cookies.get(language) === undefined
        ? getBrowserLanguage(navigator.language.split("-")[0])
        : Cookies.get(language);
    return currentLanguage.toUpperCase() === languageString;
  }

  // Obtains the language options from the backend
  getLanguages() {
    if (this.props.account.company?.languages !== undefined) {
      this.setState({ languages: this.props.account.company.languages });
    }
  }

  // Changes the language based on the icon clicked
  changeLanguage(newLanguage) {
    // Changes the language in the i18n file
    this.props.i18n.changeLanguage(newLanguage?.toLowerCase());
    Cookies.set(language, newLanguage?.toLowerCase(), { secure: isHttps, sameSite: 'lax' });
    window.location.reload();
  }

  // Handles errors encountered in this component
  errorHandler(currentOperation, error) {
    switch (currentOperation) {
      case "Change Language":
        this.mainError = this.translation(`errorMessages.languageChangeError`);
        break;
      default:
        this.mainError = this.translation(`errorMessages.internalServerError`);
        this.errorResponse = error.message;
        if (!this.state.showModalError) {
          this.toggleModalError();
        }
        return;
    }
    this.errorReason = this.translation(`errorMessages.failedToCommunicate`);
    this.errorResponse = error.message;
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  }

  render() {
    return (
      <React.Fragment>
        <div
          className="language-selector"
        >
          {this.state.languages?.map((lang) => (
            <Button
              color="primary"
              style={{ margin: "2px", width: "45px" }}
              onClick={this.changeLanguage.bind(this, lang)}
              disabled={this.isLanguageSelected(lang)}
              key={lang}
            >
              {lang.substring(0, 2)}
            </Button>
          ))}
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect((store) => ({
    account: store.account,
  }))(withTranslation()(AuthLanguage))
);
