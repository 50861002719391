import classNames from "classnames";
import Cookies from "js-cookie";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Button, Collapse, Form, Input, Nav, Navbar } from "reactstrap";
import Axios from "../axios/Axios";
import ModalError from "../components/modals/ModalError";
import { toggleSidebar } from "../redux/actions/sidebarActions";
import { language } from "../helpers/Constants";
import { getBrowserLanguage } from "../helpers/GenericHelper";
import { isHttps } from "..";
import {
  resetAccountDetails,
  setAccountDetails,
} from "../redux/actions/AccountActions";

const NavbarToggle = connect((store) => ({
  app: store.app,
}))(({ dispatch }) => {
  return (
    <span
      className="sidebar-toggle d-flex mr-2"
      onClick={() => {
        dispatch(toggleSidebar());
      }}
    >
      <i className="hamburger align-self-center" />
    </span>
  );
});

const mapDispatchToProps = (dispatch) => ({
  SetAccountDetails: (userData) => {
    dispatch(setAccountDetails(userData));
  },
  ResetAccountDetails: (userData) => {
    dispatch(resetAccountDetails(userData));
  },
  dispatch,
});

const NavbarSearch = connect((store) => ({
  sidebar: store.sidebar,
}))(({ sidebar }) => {
  return (
    <Form inline>
      <Input
        type="text"
        placeholder=""
        aria-label="Search"
        className={classNames(
          "form-control-lite",
          !sidebar.isOnRight || "text-right"
        )}
      />
    </Form>
  );
});

class NavbarClass extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      showModalError: false,
      languages: [],
    };

    this.translation = this.props.t;

    this.mainError = "";
    this.errorReason = "";
    this.errorResponse = "";

    this.toggleModalError = this.toggleModalError.bind(this);
    this.changeLanguage = this.changeLanguage.bind(this);
    this.isLanguageSelected = this.isLanguageSelected.bind(this);
    this.checkLanguageCookie = this.checkLanguageCookie.bind(this);
    this.errorHandler = this.errorHandler.bind(this);

    this.changeLanguage = this.changeLanguage.bind(this);
  }

  // Toggles the Boolean that affects appearance of modal error dialog box
  toggleModalError() {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    // Checks the language cookie if it doesnt match the users preferred language of the user
    this.checkLanguageCookie(this.props.i18n.language);
    this.getLanguages();
  }

  componentDidUpdate(prevProps) {
    // Updates the language cookie if it doesnt match the users preferred language of the user
    this.checkLanguageCookie(Cookies.get(language));
    if (prevProps !== this.props) {
      this.getLanguages();
    }
  }

  async checkLanguageCookie(currentLanguage) {
    if (this._isMounted) {
      let user = this.props.account.accountDetails;
      if (user.langKey.toLowerCase() != currentLanguage) {
        Cookies.set(language, user.langKey?.toLowerCase(), {
          secure: isHttps,
          sameSite: "lax",
        });
      }
    }
  }

  isLanguageSelected(languageString) {
    let currentLanguage =
      Cookies.get(language) === undefined
        ? getBrowserLanguage(navigator.language.split("-")[0])
        : Cookies.get(language);
    return currentLanguage.toUpperCase() === languageString;
  }
  // Obtains the language options from the backend
  getLanguages() {
    if (this.props.account.company?.languages !== undefined) {
      this.setState({ languages: this.props.account.company.languages });
    }
  }
  // Changes the language based on the icon clicked
  changeLanguage(newLanguage) {
    // Changes the language in the i18n file and lastLanguage field of the Whistleblower entity
    let user = this.props.account.accountDetails;
    user.lastLanguage = newLanguage.toUpperCase();
    user.langKey = newLanguage.toUpperCase();
    Axios.account.post(`account`, user).then((response) => {
      this.props.i18n.changeLanguage(newLanguage?.toLowerCase());
      Cookies.set(language, newLanguage?.toLowerCase(), {
        secure: isHttps,
        sameSite: "lax",
      });
      window.location.reload();
    });
  }

  // Handles errors encountered in this component
  errorHandler(currentOperation, error) {
    switch (currentOperation) {
      case "Change Language":
        this.mainError = this.translation(`errorMessages.languageChangeError`);
        break;
      default:
        this.mainError = this.translation(`errorMessages.internalServerError`);
        this.errorResponse = error.message;
        if (!this.state.showModalError) {
          this.toggleModalError();
        }
        return;
    }
    this.errorReason = this.translation(`errorMessages.failedToCommunicate`);
    this.errorResponse = error.message;
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  }

  render() {
    var NavbarLanguage = (
      <Collapse navbar>
        <Nav className={"ml-auto"} navbar style={{ padding: "5px" }}>
          {this.state.languages?.map((lang) => (
            <Button
              key={lang}
              color="primary"
              style={{ margin: "2px", width: "45px" }}
              onClick={this.changeLanguage.bind(this, lang)}
              disabled={this.isLanguageSelected(lang)}
            >
              {lang.substring(0, 2)}
            </Button>
          ))}
        </Nav>
      </Collapse>
    );
    return (
      <Navbar expand className="navbar-theme">
        <React.Fragment>
          <NavbarToggle />
          <NavbarSearch />
          {NavbarLanguage}
          <ModalError
            isOpen={this.state.showModalError}
            onClose={this.toggleModalError}
            mainError={this.mainError}
            errorReason={this.errorReason}
            errorResponse={this.errorResponse}
            modalTitle="Error"
          ></ModalError>
        </React.Fragment>
      </Navbar>
    );
  }
}

export default connect(
  (store) => ({
    account: store.account,
    sidebar: store.sidebar,
  }),
  mapDispatchToProps
)(withTranslation()(NavbarClass));
