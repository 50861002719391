import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import sessionStorage from "../../axios/SessionStorage";
import localStorage from "../../axios/LocalStorage";
import { resetAccountDetails } from "../../redux/actions/AccountActions";
import { persistor } from "../../redux/store/index";

const mapDispatchToProps = (dispatch) => ({
  ResetAccountDetails: (userData) => {
    dispatch(resetAccountDetails(userData));
  },
  dispatch,
});

class SignOut extends React.Component {
  componentDidMount() {
    sessionStorage.clearToken();
    localStorage.clearToken();

    persistor.purge();
    this.props.dispatch(resetAccountDetails());
  }

  render() {
    return <Redirect to="/sign-in" />;
  }
}

export default connect(
  (store) => ({
    account: store.account,
  }),
  mapDispatchToProps
)(SignOut);
