const LastLanguageEnums = {
  EN: "EN",
  DE: "DE",
  DE_FE: "DE_FE",
  NL: "NL",
  FR: "FR",
  SE: "SE",
  FR_BE: "FR_BE",
  FR_FE: "FR_FE",
  NO: "NO",
  FI: "FI",
  IT: "IT",
  ES: "ES",
  RU: "RU",
  CZ: "CZ",
  DK: "DK",
  EL: "EL",
  ET: "ET",
  HR: "HR",
  PL: "PL",
  PT: "PT",
};

export default LastLanguageEnums;
