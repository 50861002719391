const ApiEnums = {
  Account: "account",
  Ascending: "asc",
  Authenticate: "authenticate",
  CaseStatus: "case-statuses",
  Descending: "desc",
  Document: "documents",
  editLastLanguage: "editLastLanguage",
  ItemsPerPage: 20,
  lastLanguage: "lastLanguage",
  Message: "messages",
  MessageStatus: "message-statuses",
  MessageId: "messageId",
  Notification: "notifications",
  Register: "register",
  VerifyBeforeGettingData: "verifyBeforeGettingData",
  UploadController: "upload-controller",
  Username: "username",
  User: "users",
  UserExists: "user-exists",
  GetUserById: "get-user-by-id",
  Whistleblower: "whistleblowers",
  WhistleblowerCase: "whistleblower-cases",
  WhistleblowerCaseDraft: "whistleblower-cases/draft",
  WhistleblowerCaseId: "whistleblowerCaseId",
  WhistleblowerId: "userId",
};

export default ApiEnums;
