const LocalStorageService = (function () {
  var _service;
  function _getService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }
  function _setToken(tokenObj) {
    localStorage.setItem("jhi-authenticationtoken", tokenObj);
  }
  function _setUser(user) {
    localStorage.setItem("user", user);
  }
  function _getUser() {
    return localStorage.getItem("user");
  }
  function _getAccessToken() {
    return localStorage.getItem("jhi-authenticationtoken");
  }
  function _getRole() {
    return localStorage.getItem("role");
  }
  function _clearToken() {
    localStorage.removeItem("user_id");
    localStorage.removeItem("role");
    localStorage.removeItem("user");
    localStorage.removeItem("jhi-authenticationtoken");
  }
  return {
    setUser: _setUser,
    getUser: _getUser,
    getService: _getService,
    setToken: _setToken,
    getAccessToken: _getAccessToken,
    getRole: _getRole,
    clearToken: _clearToken,
  };
})();

export default LocalStorageService;
