import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import { store } from "../redux/store/index";
import ModalOK from "../components/modals/ModalOK";
import draftToHtml from "draftjs-to-html";

class Footer extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      allLinks: [],
      showModal: false,
      render: false, //Set render state to false
    };

    this.translation = this.props.t;

    this.modalTitle = "";
    this.modalBodyText = "";
  }

  componentDidMount() {
    this._isMounted = true;
    // Needed to buy time to ensure it loaded properly. 1 milisecond seems sufficent.
    setTimeout(
      function () {
        if (this._isMounted) {
          this.setState({ render: true });
        }
      }.bind(this),
      50
    );
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  loadModalText = (item) => {
    const misc = store.getState().account?.company?.miscInformation ?? null;
    this.modalTitle = this.translation(`footer.` + item);
    this.modalBodyText = draftToHtml(misc[item]);
    this.toggleModal();
  };

  // Toggles the Boolean that affects appearance of modal dialog box
  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  render() {
    const { t } = this.props;
    return this.state.render ? (
      <footer className="footer">
        <Container fluid>
          <Row className="text-muted">
            <Col xs={8} className="text-left">
              <ul className="list-inline">
                <li className="list-inline-item">
                  <a style={{ cursor: "pointer" }}
                    onClick={() => this.loadModalText("privacy")}
                    className="text-muted mr-1"
                  >
                    {t(`footer.privacy`)}
                  </a>
                </li>
                <li className="list-inline-item">
                  <a style={{ cursor: "pointer" }}
                    onClick={() => this.loadModalText("contact")}
                    className="text-muted mr-1"
                  >
                    {t(`footer.contact`)}
                  </a>
                </li>
                <li className="list-inline-item">
                  <a style={{ cursor: "pointer" }}
                    onClick={() => this.loadModalText("imprint")}
                    className="text-muted mr-1"
                  >
                    {t(`footer.imprint`)}
                  </a>
                </li>
              </ul>
            </Col>
            <Col xs={4} className="text-right">
              <p className="mb-0">
                &copy; {new Date().getFullYear()} -{" "}
                <a href="https://www.invensity.com" className="text-muted">
                  INVENSITY
                </a>
              </p>
            </Col>
          </Row>
        </Container>
        <ModalOK
          isOpen={this.state.showModal}
          onClose={this.toggleModal}
          modalTitle={this.modalTitle}
          modalBodyText={
            <div dangerouslySetInnerHTML={{ __html: this.modalBodyText }} />
          }
          size="xl"
        />
      </footer>
    ) : null;
  }
}

export default connect((store) => ({
  app: store.app,
}))(withTranslation()(Footer));
