const MessageStatusEnum = {
  READ: "READ",
  SENT: "SENT",
  DRAFT: "DRAFT",
  ANSWERED: "ANSWERED",
  RECEIVED: "RECEIVED",
  UNREAD: "UNREAD"
};

export default MessageStatusEnum;
