import {
  faEye,
  faEyeSlash,
  faSignInAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AvField,
  AvForm,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";
import ReCAPTCHA from "react-google-recaptcha";
import Cookies from "js-cookie";
import _debounce from "lodash.debounce";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Input, Label } from "reactstrap";
import Axios from "../../axios/Axios";
import sessionStorage from "../../axios/SessionStorage";
import localStorage from "../../axios/LocalStorage";
import FadeAlert from "../../components/FadeAlert";
import ApiEnums from "../../enums/ApiEnums";
import RoleEnum from "../../enums/RoleEnums";
import { getBrowserLanguage } from "../../helpers/GenericHelper";
import { language } from "../../helpers/Constants";
import i18n from "../../i18n";
import {
  resetAccountDetails,
  setAccountDetails,
} from "../../redux/actions/AccountActions";
import { persistor } from "../../redux/store/index";

// Maps dispatch to props so that account details are stored and are accessible within the app
const mapDispatchToProps = (dispatch) => ({
  SetAccountDetails: (userData) => {
    dispatch(setAccountDetails(userData));
  },
  ResetAccountDetails: (userData) => {
    dispatch(resetAccountDetails(userData));
  },
  dispatch,
});

/*
 * Class that handles sign up for new users of the client dashboard
 */
class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      firstName: "",
      password: "",
      confirmPassword: "",
      errorMessage: "",
      logInAfterSignUp: false,
      showPassword: false,
      showConfirmPassword: false,
      passwordSettings: {},
      captchaStatus: false,
      captchaSiteKey: "",
    };

    this.translation = this.props.t;
    this.firstIndex = 0;

    // binds
    this.handleUsernameOnchange = this.handleUsernameOnchange.bind(this);
    this.handlePasswordOnchange = this.handlePasswordOnchange.bind(this);
    this.handleLogInAfterSignUp = this.handleLogInAfterSignUp.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.showHidePassword = this.showHidePassword.bind(this);
    this.showHideConfirmPassword = this.showHideConfirmPassword.bind(this);
    this.errorHandlerManual = this.errorHandlerManual.bind(this);
    this.onCAPTCHAExpiry = this.onCAPTCHAExpiry.bind(this);
    this.errorHandler = this.errorHandler.bind(this);
    this.recaptchaRef = React.createRef();
  }

  onCAPTCHAChange(value) {
    this.recaptchaRef.current.focus();
  }

  onCAPTCHAExpiry() {
    this.errorHandlerManual(this.translation(`fieldWarnings.expiredCAPTCHA`));
    this.recaptchaRef.current.reset();
    grecaptcha.reset();
  }

  // Commands to execute when the component is mounting.
  componentDidMount() {
    // redirect to home if already logged
    if (
      sessionStorage.getAccessToken() &&
      sessionStorage.getAccessToken() !== ""
    ) {
      this.props.history.push("/");
    }
    // Gets the CAPTCHA site key IF it exists
    Axios.clientService.get(`re-captcha-site-key`).then((response) => {
      if (response.data.toString() !== "") {
        this.setState({
          captchaStatus: true,
          captchaSiteKey: response.data.toString(),
        });
      }
    });
  }

  // Handles the changes to the username field
  handleUsernameOnchange(event) {
    this.setState({
      firstName: event.target.value,
      username: event.target.value.toLowerCase(),
    });
  }

  // Handles the changes to the password field
  handlePasswordOnchange(event) {
    this.setState({ password: event.target.value });
  }

  // Handles the changes to the confirm-password field
  handleConfirmPasswordOnchange(event) {
    this.setState({ confirmPassword: event.target.value });
  }

  // Handles the changes to the "log in after sign up" checkbox
  handleLogInAfterSignUp() {
    this.setState({ logInAfterSignUp: !this.state.logInAfterSignUp });
  }

  // Handles submission of new user; also checks if username already exists
  handleValidSubmit() {
    let newUser = {
      login: this.state.username,
      firstName: this.state.firstName,
      password: this.state.password,
      activated: true,
      authorities: [RoleEnum.USER],
      reCAPTCHAData: this.recaptchaRef.current?.getValue() ?? null,
      langKey:
        Cookies.get(language) !== undefined
          ? Cookies.get(language)
          : getBrowserLanguage(i18n.language),
    };

    document.getElementById("signUp").innerHTML = this.translation(
      `signUp.signingUpButtonText`
    );
    document.getElementById("signUp").disabled = true;

    if (
      !this.state.captchaStatus ||
      (this.state.captchaStatus && newUser.reCAPTCHAData !== "")
    ) {
      Axios.account
        .post(ApiEnums.Register, newUser)
        .then(() => {
          if (this.state.logInAfterSignUp) {
            Axios.account
              .post(ApiEnums.Authenticate, {
                username: this.state.username,
                password: this.state.password
              })
              .then((response) => {
                if (response.data && response.data?.id_token) {
                  sessionStorage.setToken(response.data.id_token);
                  localStorage.setToken(response.data.id_token);
                  Axios.account
                    .post(`${ApiEnums.User}/${ApiEnums.GetUserById}`, {
                      name: this.state.username,
                    })
                    .then((accountResponse) => {
                      let userData = accountResponse;
                      this.props.dispatch(setAccountDetails(userData.data));
                      this.props.history.push("/");
                    })
                    .catch((error) => {
                      sessionStorage.clearToken();
                      localStorage.clearToken();
                      persistor.purge();

                      this.errorHandler(error);
                    });
                }
              })
              .catch((error) => {
                this.errorHandler(error);
              });
          } else {
            this.props.history.push({
              pathname: "/sign-in",
              state: {
                successMessage: `${this.state.firstName} ${this.translation(
                  `signUp.hasBeenCreated`
                )}`,
              },
            });
          }
        })
        .catch((error) => {
          this.errorHandler(error);
        });
    } else {
      this.errorHandlerManual(this.translation(`fieldWarnings.missingCAPTCHA`));
    }
  }

  // Either shows or hides the contents of the password field
  showHidePassword(event) {
    event.preventDefault();
    this.setState({ showPassword: !this.state.showPassword });
  }

  // Either shows or hides the contents of the confirm password field
  showHideConfirmPassword(event) {
    event.preventDefault();
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  }

  // Validations for the username field
  validateUsername = _debounce((value, ctx, input, cb) => {
    clearTimeout(this.timeout);
    var regex = new RegExp("^[a-zA-Z0-9.\\-_]*$");
    this.timeout = setTimeout(() => {
      if (value === "") {
        cb(this.translation(`fieldWarnings.enterUsername`));
      } else if (!regex.test(value)) {
        cb(this.translation(`fieldWarnings.specialCharacters`));
      } else {
        cb(true);
      }
    }, 500);
  }, 300);

  // Validations for the confirm password field
  validateConfirmPassword = _debounce((value, ctx, input, cb) => {
    // cancel pending 'network call'
    clearTimeout(this.timeout);

    // simulate network call
    this.timeout = setTimeout(() => {
      if (value === "") {
        cb(this.translation(`fieldWarnings.confirmPassword`));
      } else if (value !== this.state.password) {
        cb(this.translation(`fieldWarnings.passwordsDoNotMatch`));
      } else {
        cb(true);
      }
    }, 500);
  }, 300);

  // Handles errors in this page
  errorHandler(error) {
    document.getElementById("signUp").innerHTML = this.translation(
      `signUp.signUpButtonText`
    );
    document.getElementById("signUp").disabled = false;

    let errorMessage = "";
    if (error.message === "Network Error") {
      errorMessage = this.translation(`errorMessages.networkError`);
    } else if (error.message === "CAPTCHA Error") {
      errorMessage = this.translation(`errorMessages.missingCAPTCHA`);
    } else if (error?.response?.data?.message === "error.userexists") {
      errorMessage = this.translation(`fieldWarnings.usernameTaken`);
    } else {
      errorMessage = this.translation(`errorMessages.internalServerError`);
    }

    this.setState({
      errorMessage: errorMessage,
    });

    setTimeout(() => {
      this.setState({
        errorMessage: "",
      });
    }, 5000);
  }

  // Handles manually called errors in this page
  errorHandlerManual(errorMessage) {
    document.getElementById("signUp").innerHTML = this.translation(
      `signUp.signUpButtonText`
    );
    document.getElementById("signUp").disabled = false;

    this.setState({
      errorMessage: errorMessage,
    });

    setTimeout(() => {
      this.setState({
        errorMessage: "",
      });
    }, 5000);
  }

  render() {
    return (
      <React.Fragment>
        <div className="sign-up">
          <div className="sign-up">
            <Card>
              <CardHeader>
                {this.state.errorMessage !== "" && (
                  <FadeAlert color="danger">
                    {this.state.errorMessage}
                  </FadeAlert>
                )}
              </CardHeader>
              <CardBody>
                <div className="text-center mt-4">
                  <p className="lead">
                    {this.translation(`signUp.signUpHeader`)}
                  </p>
                  <p>
                    {this.translation(`signUp.signUpInstruction1`)}{" "}
                    {this.translation(`signUp.signUpInstruction2`)}
                  </p>
                </div>
                <div className="m-sm-4">
                  <AvForm onValidSubmit={this.handleValidSubmit}>
                    <AvGroup>
                      <Input
                        name="username"
                        type="text"
                        style={{ display: "none" }}
                      />
                      <AvField
                        name="username"
                        placeholder={this.translation(`fieldLabels.username`)}
                        type="text"
                        validate={{ async: this.validateUsername }}
                        onChange={this.handleUsernameOnchange}
                      />
                    </AvGroup>
                    <AvGroup className="passwordField">
                      <AvField
                        name="password"
                        placeholder={this.translation(`fieldLabels.password`)}
                        type={this.state.showPassword ? "text" : "password"}
                        value={this.state.password}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: this.translation(
                              `fieldWarnings.enterPassword`
                            ),
                          },
                          minLength: {
                            value: 4,
                            errorMessage: this.translation(
                              `fieldWarnings.passwordMinLength`,
                              {
                                length: 4,
                              }
                            ),
                          },
                          maxLength: {
                            value: 100,
                            errorMessage: this.translation(
                              `fieldWarnings.passwordMaxLength`
                            ),
                          },
                        }}
                        onChange={this.handlePasswordOnchange}
                      />
                      <FontAwesomeIcon
                        icon={this.state.showPassword ? faEyeSlash : faEye}
                        onClick={this.showHidePassword}
                        className="eyeIcon"
                      />
                    </AvGroup>
                    <AvGroup className="passwordField">
                      <AvField
                        name="confirmPassword"
                        placeholder={this.translation(
                          `fieldLabels.confirmPassword`
                        )}
                        type={
                          this.state.showConfirmPassword ? "text" : "password"
                        }
                        validate={{ async: this.validateConfirmPassword }}
                      />
                      <FontAwesomeIcon
                        icon={
                          this.state.showConfirmPassword ? faEyeSlash : faEye
                        }
                        onClick={this.showHideConfirmPassword}
                        className="eyeIcon"
                      />
                    </AvGroup>
                    <div>
                      <AvGroup check>
                        <Label check>
                          <AvInput
                            type="checkbox"
                            name="checkbox"
                            onChange={this.handleLogInAfterSignUp}
                          />{" "}
                          {this.translation(`signUp.logInAfterSignUp`)}
                        </Label>
                      </AvGroup>
                    </div>
                    <div className="text-center mt-3">
                      {this.state.captchaStatus && (
                        <ReCAPTCHA
                          ref={this.recaptchaRef}
                          sitekey={this.state.captchaSiteKey}
                          onChange={this.onCAPTCHAChange}
                          onExpired={this.onCAPTCHAExpiry}
                        />
                      )}
                    </div>
                    <div className="text-center mt-3">
                      <Button color="primary" size="lg" id="signUp">
                        <FontAwesomeIcon icon={faSignInAlt} />
                        {" " + this.translation(`signUp.signUpButtonText`)}
                      </Button>
                    </div>
                  </AvForm>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(
    (store) => ({
      account: store.account,
    }),
    mapDispatchToProps
  )(withTranslation()(SignUp))
);
