import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import SessionStorage from "../axios/SessionStorage";
import ScrollToTop from "../components/ScrollToTop";
import AuthLayout from "../layouts/Auth";
import FullWidthPageLayout from "../layouts/FullWidthLayoutPage";
import DashboardLayout from "../layouts/Dashboard";
import Page404 from "../pages/auth/Page404";
import { dashboard as dashboardRoutes, page as pageRoutes, bothLayoutPage as bothLayoutPageRoutes } from "./index";
import CookieBanner from "../components/CookieBanner";
import { store } from "../redux/store/index";
import RoleEnum from "../enums/RoleEnums";
import Auth from "../layouts/Auth";

const childRoutes = (Layout, routes) =>
  routes.map(
    ({ children, path, component: Component, isProtected = false, roles = []}, index) =>
      children ? (
        // Route item with children
        children.map(({ path, component: Component }, index) =>
          isProtected ? (
            <ProtectedRoute
              isProtected={isProtected}
              path={path}
              key={index}
              roles={roles}
              exact
              component={(props) => (
                <Layout>
                  <Component {...props} />
                </Layout>
              )}
            />
          ) : (
            <Route
              key={index}
              path={path}
              exact
              render={(props) => (
                <Layout>
                  <Component {...props} />
                </Layout>
              )}
            />
          )
        )
      ) : // Route item without children
      isProtected ||
      store.getState().account.accountDetails?.authorities?.includes(roles)? (
        <ProtectedRoute
          path={path}
          key={index}
          roles={roles}
          exact
          component={(props) => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      ) : (
        <Route
          key={index}
          path={path}
          exact
          render={(props) => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      )
  );

const Routes = () => (
  <Router>
    <ScrollToTop>
      <Switch>
        {childRoutes(DashboardLayout, dashboardRoutes)}
        {childRoutes(AuthLayout, pageRoutes)}
        {childRoutes(FullWidthPageLayout, bothLayoutPageRoutes)}
        <Route
          render={() => (
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          )}
        />
      </Switch>
    </ScrollToTop>
    <CookieBanner/>
  </Router>
);

const ProtectedRoute = ({ name: Name, component: Component, roles, ...props }) => (
  <Route
    {...props}
    render={(props) =>
      SessionStorage.getAccessToken() === null ?
        window.location.replace("/auth/sign-out") :
      (SessionStorage.getAccessToken() !== null && roles.length === 0)  ||
      (SessionStorage.getAccessToken() !== null &&
      roles.some(role => store.getState().account.accountDetails?.authorities?.includes(role)))
      ? (
        <Component {...props} />
      ) : store.getState().account.accountDetails?.authorities?.includes(RoleEnum.ADMIN) && window.location.pathname === ("/")
      ? window.location.replace("/settings/frontend") : (<Auth children={<Page404 />} />)
    }
  />
);

export default Routes;
