import { faSave, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
import React, { createRef } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Button, Label } from "reactstrap";
import Axios from "../../axios/Axios";
import ModalError from "../../components/modals/ModalError";
import { LoadingIndicator } from "../../helpers/GenericHelper";
import { store } from "../../redux/store/index";
import Message from "../../components/messages/Message";
import CaseStatusEnum from "../../enums/CaseStatusEnum";
import moment from "moment";
/*
 * Class for saving meta information
 * This shows up in a modal dialog box
 */
class MetaInformationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contactData: this.props.whistleblowerCase.metaInformation?.contactData,
      violation: this.props.whistleblowerCase.metaInformation?.violation,
      occurenceTime:
        this.props.whistleblowerCase.metaInformation?.occurrenceTime,
      location: this.props.whistleblowerCase.metaInformation?.location,
      involvedPeople:
        this.props.whistleblowerCase.metaInformation?.involvedPeople,
      witness: this.props.whistleblowerCase.metaInformation?.witness,
      expectations: this.props.whistleblowerCase.metaInformation?.expectations,
      id: this.props.whistleblowerCase.metaInformation?.id,
      showModalError: false,
    };
    this.translation = this.props.t;
    this.firstIndex = 0;

    this.currentUserId = store.getState().account.accountDetails.id;

    this.mainError = "";
    this.errorReason = "";
    this.errorResponse = "";

    this.toggleModalError = this.toggleModalError.bind(this);
    this.saveMetaInformation = this.saveMetaInformation.bind(this);
    this.sendMetaInformation = this.sendMetaInformation.bind(this);
    this.errorHandler = this.errorHandler.bind(this);

    this.handleContactDataChange = this.handleContactDataChange.bind(this);
    this.handleViolationChange = this.handleViolationChange.bind(this);
    this.handleOccurrenceTimeChange =
      this.handleOccurrenceTimeChange.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.handleInvolvedPeopleChange =
      this.handleInvolvedPeopleChange.bind(this);
    this.handleWitnessChange = this.handleWitnessChange.bind(this);
    this.handleExpectationChange = this.handleExpectationChange.bind(this);
    this.savingErrorCode = "MetaInformationSave";

    this.messageRef = createRef();
  }

  // Toggles the Boolean that affects appearance of modal error dialog box
  toggleModalError() {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  }

  // Saves the meta information and toggles the modal.
  async saveMetaInformation(event) {
    let { whistleblowerCase } = this.props;

    if (whistleblowerCase.metaInformation) {
      // Update meta information then save to whistleblower case
      let metaInformation = await Axios.clientService
        .put(`meta-informations`, this.state)
        .then((response) => {
          return response.data;
        });
      whistleblowerCase.metaInformation = metaInformation;

      whistleblowerCase.metaInformation = this.state;
      Axios.clientService
        .put(`whistleblower-cases`, whistleblowerCase)
        .then(() => {
          this.props.setSuccessMessage(
            this.translation(`metaInformation.successfulMessage`)
          );
          this.props.onSubmit();
        })
        .catch((error) => {
          this.errorHandler(this.savingErrorCode, error);
        });
    } else {
      // Create meta information then save to whistleblower case
      let metaInformation = await Axios.clientService
        .post(`meta-informations`, this.state)
        .then((response) => {
          return response.data;
        });
      whistleblowerCase.metaInformation = metaInformation;

      Axios.clientService
        .put(`whistleblower-cases`, whistleblowerCase)
        .then((response) => {
          this.props.setSuccessMessage(
            this.translation(`metaInformation.successfulMessage`)
          );
          // This is to check if there is any content to the message at all. if there is none, no message will be sent.
          if (!this.props.isEdit) {
            if (
              this.messageRef.state.editorState.getCurrentContent().hasText() ||
              this.messageRef.state.attachedFiles.length !== 0
            ) {
              this.messageRef && this.messageRef.saveMessage(true);
            }
          }
          this.props.onSubmit();
        });
    }
  }

  // Sends the meta information and toggles the modal.
  async sendMetaInformation(event) {
    let { whistleblowerCase } = this.props;

    if (whistleblowerCase.metaInformation) {
      // Update meta information then save to whistleblower case
      let metaInformation = await Axios.clientService
        .put(`meta-informations`, this.state)
        .then((response) => {
          return response.data;
        });
      whistleblowerCase.metaInformation = metaInformation;

      whistleblowerCase.metaInformation = this.state;
      await Axios.clientService
        .put(`whistleblower-cases`, whistleblowerCase)
        .then(() => {
          this.props.setSuccessMessage(
            this.translation(`metaInformation.successfulMessage`)
          );
          this.props.onSubmit();
        })
        .catch((error) => {
          this.errorHandler(this.savingErrorCode, error);
        });
    } else {
      // Create meta information then save to whistleblower case
      let metaInformation = await Axios.clientService
        .post(`meta-informations`, this.state)
        .then((response) => {
          return response.data;
        });
      whistleblowerCase.metaInformation = metaInformation;

      await Axios.clientService
        .put(`whistleblower-cases`, whistleblowerCase)
        .then(async (response) => {
          this.props.setSuccessMessage(
            this.translation(`metaInformation.successfulMessage`)
          );

          // This is to check if there is any content to the message at all. if there is none, no message will be sent.
          if (!this.props.isEdit) {
            if (
              this.messageRef.state.editorState.getCurrentContent().hasText() ||
              this.messageRef.state.attachedFiles.length !== 0
            ) {
              this.messageRef && this.messageRef.saveMessage(false);
            }
          }

          let newStatus = {
            whistleblowerCase: response.data,
            statusOfCase: CaseStatusEnum.OPEN,
            dateTime: moment(),
          };
         await Axios.clientService.post(`case-statuses`, newStatus);

          this.props.onSubmit();
          this.props.updateCaseStatus();
        });
    }
  }

  // Catches the errors encountered within this component
  errorHandler(currentOperation, error) {
    if (currentOperation === this.savingErrorCode) {
      this.mainError = this.translation(`errorMessages.caseCreationError`);
    }
    this.errorReason = this.translation(`errorMessages.failedToCommunicate`);
    this.errorResponse = error.message;
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  }

  // Handles the changing of the case title
  handleContactDataChange(e) {
    this.setState({ contactData: e.target.value });
  }

  handleViolationChange(e) {
    this.setState({ violation: e.target.value });
  }

  handleOccurrenceTimeChange(e) {
    this.setState({ occurenceTime: e.target.value });
  }

  handleLocationChange(e) {
    this.setState({ location: e.target.value });
  }

  handleInvolvedPeopleChange(e) {
    this.setState({ involvedPeople: e.target.value });
  }

  handleWitnessChange(e) {
    this.setState({ witness: e.target.value });
  }

  handleExpectationChange(e) {
    this.setState({ expectations: e.target.value });
  }

  render() {
    let { whistleblowerCase } = this.props;
    return (
      <React.Fragment>
        <LoadingIndicator />
        <AvForm>
          <AvGroup>
            <Label>{this.translation(`metaInformation.header`)}</Label>
          </AvGroup>
          <AvGroup>
            <Label>{this.translation(`metaInformation.contactData`)}</Label>
            <AvField
              rows={3}
              name="contactData"
              type="textarea"
              value={whistleblowerCase.metaInformation?.contactData}
              onChange={this.handleContactDataChange}
            />
          </AvGroup>

          <AvGroup>
            <Label>{this.translation(`metaInformation.violation`)}</Label>
            <AvField
              rows={3}
              name="violation"
              type="textarea"
              value={whistleblowerCase.metaInformation?.violation}
              onChange={this.handleViolationChange}
            />
          </AvGroup>

          <AvGroup>
            <Label>{this.translation(`metaInformation.occurrenceTime`)}</Label>
            <AvField
              rows={3}
              name="occurrenceTime"
              type="textarea"
              value={whistleblowerCase.metaInformation?.occurenceTime}
              onChange={this.handleOccurrenceTimeChange}
            />
          </AvGroup>

          <AvGroup>
            <Label>{this.translation(`metaInformation.location`)}</Label>
            <AvField
              rows={3}
              name="location"
              type="textarea"
              value={whistleblowerCase.metaInformation?.location}
              onChange={this.handleLocationChange}
            />
          </AvGroup>

          <AvGroup>
            <Label>{this.translation(`metaInformation.involvedPeople`)}</Label>
            <AvField
              rows={3}
              name="involvedPeople"
              type="textarea"
              value={whistleblowerCase.metaInformation?.involvedPeople}
              onChange={this.handleInvolvedPeopleChange}
            />
          </AvGroup>

          <AvGroup>
            <Label>{this.translation(`metaInformation.witness`)}</Label>
            <AvField
              rows={3}
              name="witness"
              type="textarea"
              value={whistleblowerCase.metaInformation?.witness}
              onChange={this.handleWitnessChange}
            />
          </AvGroup>

          <AvGroup>
            {!this.props.isEdit ? (
              <React.Fragment>
                <Label>{this.translation(`metaInformation.expectation`)}</Label>
                <Label>
                  {this.translation(`metaInformation.expectation-2`)}
                </Label>
                <Message
                  caseId={this.props.whistleblowerCase.id}
                  draftMessage={null}
                  messageBoxOnly={true}
                  onRef={(ref) => (this.messageRef = ref)}
                  updateWhistleblowercaseState={
                    this.props.updateWhistleblowercaseState
                  }
                ></Message>
              </React.Fragment>
            ) : null}

            <Label>{this.translation(`metaInformation.expectation-3`)}</Label>
            <AvField
              rows={3}
              name="expectation"
              type="textarea"
              value={whistleblowerCase.metaInformation?.expectations}
              onChange={this.handleExpectationChange}
            />
          </AvGroup>

          <div className="text-center mt-3">
            <Button color="primary" onClick={this.saveMetaInformation}>
              {<FontAwesomeIcon icon={faSave} />}{" "}
              {!this.props.isEdit
                ? this.translation(`messageComponent.saveAsDraft`)
                : this.translation(`commonButtonTexts.save`)}
            </Button>{" "}
            {!this.props.isEdit ? (
              <Button color="primary" onClick={this.sendMetaInformation}>
                {<FontAwesomeIcon icon={faPaperPlane} />}{" "}
                {this.translation(`messageComponent.send`)}
              </Button>
            ) : null}
          </div>
        </AvForm>
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.mainError}
          errorReason={this.errorReason}
          errorResponse={this.errorResponse}
          modalTitle="Error"
        ></ModalError>
      </React.Fragment>
    );
  }
}

export default withRouter(withTranslation()(MetaInformationForm));
