import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import i18n from "../../i18n";

/**
 * ModaDelete contains commands that would enable a modal dialog for deletion
 * to be opened up in a webpage
 */
const ModalDelete = (props) => {
  const { modalTitle, modalBodyText, event, isOpen, onClose } = props;

  const [modal, setModal] = useState(isOpen);

  const toggle = (e) => {
    setModal(!modal);
    onClose && onClose(e);
  };
  const deleteEvent = () => {
    event().then(() => {
      toggle();
    });
  };

  useEffect(() => {
    setModal(isOpen);
  }, [isOpen]);

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
        <ModalBody>{modalBodyText}</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={deleteEvent}>
            {i18n.t(`commonButtonTexts.delete`)}
          </Button>{" "}
          <Button color="dark" onClick={toggle}>
            {i18n.t(`commonButtonTexts.cancel`)}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalDelete;
