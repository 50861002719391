import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { Badge, Collapse } from "reactstrap";
import { store } from "../redux/store/index";
import routes from "../routes/index";

const SidebarCategory = withRouter(
  ({
    name,
    badgeColor,
    badgeText,
    icon,
    isOpen,
    children,
    onClick,
    location,
    to,
  }) => {
    const getSidebarItemClass = (path) => {
      return location.pathname.indexOf(path) !== -1 ||
        (location.pathname === "/" && path === "/dashboard")
        ? "active"
        : "";
    };

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <span
          data-toggle="collapse"
          className={"sidebar-link " + (!isOpen ? "collapsed" : "")}
          onClick={onClick}
          aria-expanded={isOpen ? "true" : "false"}
        >
          <FontAwesomeIcon
            icon={icon}
            fixedWidth
            className="align-middle mr-2"
          />{" "}
          <span className="align-middle">{name}</span>
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} pill className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </span>
        <Collapse isOpen={isOpen}>
          <ul id="item" className={"sidebar-dropdown list-unstyled"}>
            {children}
          </ul>
        </Collapse>
      </li>
    );
  }
);

const SidebarItem = withRouter(
  ({ name, badgeColor, badgeText, icon, location, to }) => {
    const getSidebarItemClass = (path) => {
      return location.pathname === path ? "active" : "";
    };

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <NavLink to={to} className="sidebar-link" activeClassName="active">
          {icon ? (
            <React.Fragment>
              <FontAwesomeIcon
                icon={icon}
                fixedWidth
                className="align-middle mr-2"
              />{" "}
              <span className="align-middle">{name}</span>
            </React.Fragment>
          ) : (
            name
          )}{" "}
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} pill className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </NavLink>
      </li>
    );
  }
);

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.firstIndex = 0;
  }

  toggle = (index) => {
    // Collapse all elements
    Object.keys(this.state).forEach(
      (item) =>
        this.state[index] ||
        this.setState(() => ({
          [item]: false,
        }))
    );

    // Toggle selected element
    this.setState((state) => ({
      [index]: !state[index],
    }));
  };

  async componentDidMount() {
    /* Open collapse element that matches current url */
    const pathName = this.props.location.pathname;

    routes.forEach((route, index) => {
      if (
        route.roles === null ||
        !route.hasOwnProperty("roles") ||
        route.roles.some((role) =>
          store.getState().account.accountDetails?.authorities?.includes(role)
        )
      ) {
        const isActive = pathName.indexOf(route.path) === 0;
        const isOpen = route.open;
        const isHome = route.containsHome && pathName === "/" ? true : false;

        this.setState(() => ({
          [index]: isActive || isOpen || isHome,
        }));
      }
    });
  }

  render() {
    const { sidebar, t } = this.props;
    const { accountDetails } = store.getState().account;
    const name = accountDetails?.login;
    return (
      <nav
        className={classNames(
          "sidebar",
          sidebar.isOpen || "toggled",
          !sidebar.isOnRight || "sidebar-right"
        )}
      >
        <div className="sidebar-content">
          <a
            className={classNames(
              "sidebar-brand",
              !sidebar.isOnRight || "text-right"
            )}
            href="/"
            style={{ padding: 0 }}
          >
            <div
              style={{
                backgroundColor: "white",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                padding: "10px",
              }}
            >
              <div
                style={{
                  backgroundImage: `url(/api/upload-controller/get-logo)`,
                  backgroundSize: "contain",
                  height: "100px",
                  backgroundColor: "white",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              />
            </div>
            <div className="text-center" style={{ padding: "10px" }}>
              {t(`sideBar.wbPortal`)}
            </div>{" "}
          </a>

          <div className="sidebar-user">
            <div>
              <p>
                {t(`sideBar.currentUser`)}{" "}
                <span className="font-weight-bold text-capitalize">{name}</span>
              </p>
            </div>
          </div>
          <ul className="sidebar-nav">
            {routes.map((category, index) => {
              if (
                category.roles === null ||
                !category.hasOwnProperty("roles") ||
                category.roles.some((role) =>
                  store
                    .getState()
                    .account.accountDetails?.authorities?.includes(role)
                )
              ) {
                return (
                  <React.Fragment key={index}>
                    {category.header ? (
                      <li className="sidebar-header">{category.header}</li>
                    ) : null}

                    {category.children ? (
                      <SidebarCategory
                        name={category.name}
                        badgeColor={category.badgeColor}
                        badgeText={category.badgeText}
                        icon={category.icon}
                        to={category.path}
                        isOpen={this.state[index]}
                        onClick={() => this.toggle(index)}
                      >
                        {category.children.map((route, index) => (
                          <SidebarItem
                            key={index}
                            name={route.name}
                            to={route.path}
                            badgeColor={route.badgeColor}
                            badgeText={route.badgeText}
                          />
                        ))}
                      </SidebarCategory>
                    ) : (
                      <SidebarItem
                        name={category.name}
                        to={category.path}
                        icon={category.icon}
                        badgeColor={category.badgeColor}
                        badgeText={category.badgeText}
                      />
                    )}
                  </React.Fragment>
                );
              }
              return null;
            })}
          </ul>
        </div>
      </nav>
    );
  }
}

export default withRouter(
  connect((store) => ({
    sidebar: store.sidebar
  }))(withTranslation()(Sidebar))
);
