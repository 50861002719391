import {
  faPaperclip,
  faPaperPlane,
  faSave,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import moment from "moment";
import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  UncontrolledTooltip,
  Row,
  Col,
} from "reactstrap";
import Axios from "../../axios/Axios";
import ModalDelete from "../../components/modals/ModalDelete";
import ModalError from "../../components/modals/ModalError";
import ApiEnums from "../../enums/ApiEnums";
import FlagEnums from "../../enums/FlagEnums";
import MessageStatusEnum from "../../enums/MessageStatusEnum";
import {
  isEmpty,
  LoadingIndicator,
  removeByAttr,
} from "../../helpers/GenericHelper";
import { store } from "../../redux/store/index";
import DynamicTable from "../DynamicTable";
import FadeAlert from "../FadeAlert";

/*
 * Class that handles creation, sending, and saving of messages
 */
class Message extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subject: this.props.subject,
      uploadedImages: [],
      attachedFiles: [],
      draftDocumentObjects: [],
      draftDocumentFiles: [],
      previousMessageStatus: null,
      hasAccess: false,
      showModalDelete: false,
      showModalError: false,
      editorState: EditorState.createEmpty(),
      errorMessage: "",
      whistleblowerCase: null,
      value: "",
    };
    this.translation = this.props.t;
    this.firstIndex = 0;

    this.mainError = "";
    this.errorReason = "";
    this.errorResponse = "";

    this.modalDeleteEvent = null;

    this.toggleModalDelete = this.toggleModalDelete.bind(this);
    this.toggleModalError = this.toggleModalError.bind(this);
    this.setUpDraft = this.setUpDraft.bind(this);
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
    this.uploadImageCallBack = this.uploadImageCallBack.bind(this);
    this.saveMessage = this.saveMessage.bind(this);
    this.getFileLocation = this.getFileLocation.bind(this);
    this.deleteDraftDocument = this.deleteDraftDocument.bind(this);
    this.removeFileFromArray = this.removeFileFromArray.bind(this);
    this.redirectToPreviousPage = this.redirectToPreviousPage.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.attachFiles = this.attachFiles.bind(this);
    this.errorHandler = this.errorHandler.bind(this);
    this.removeFile = this.removeFile.bind(this);
  }

  // Commands to run when component is mounting
  componentDidMount() {
    let storeState = store.getState();
    this.props.onRef && this.props.onRef(this);
    let accountDetails = storeState.account.accountDetails;
    if (this.props.draftMessage !== null) {
      this.setUpDraft();
    }
  }

  // Sets up the draft
  setUpDraft() {
    const DBEditorState = convertFromRaw(
      JSON.parse(this.props.draftMessage?.message)
    );

    this.setState({
      subject: this.props.draftMessage?.subject,
      editorState: EditorState.createWithContent(DBEditorState),
      previousMessageStatus:
        this.props.draftMessage?.messageStatuses[this.firstIndex],
      draftDocumentObjects: this.props.draftMessage?.documents,
    });
  }

  // Toggles the Boolean that affects appearance of modal delete dialog box
  toggleModalDelete() {
    this.setState({
      showModalDelete: !this.state.showModalDelete,
    });
  }

  // Toggles the Boolean that affects appearance of modal error dialog box
  toggleModalError() {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  }

  // Uploads the image callback file
  uploadImageCallBack(file) {
    let uploadedImages = this.state.uploadedImages;
    const imageObject = {
      file: file,
      localSrc: URL.createObjectURL(file),
    };
    uploadedImages.push(imageObject);
    this.setState({ uploadedImages: uploadedImages });
    return new Promise((resolve, reject) => {
      resolve({ data: { link: imageObject.localSrc } });
    });
  }

  // Detects changes to editor
  onEditorStateChange(editorState) {
    this.setState({
      editorState,
    });
  }

  // Handles the processing of the message and saving it to the database
  async saveMessage(isDraft = false) {
    // Combines files from the recently attached files array and the draft documents array
    // They are separate so that, when deleting a draft document, any document with a name similar to it
    // will not be removed from the recently attached files array
    let combinedFilesArray = this.state.attachedFiles;
    combinedFilesArray = combinedFilesArray.concat(
      this.state.draftDocumentFiles
    );

    // api call to save jsonData
    let message = {
      id: this.props.draftMessage ? this.props.draftMessage.id : null,
      subject: this.state.subject,
      flag: FlagEnums.OUTGOING,
      whistleblowerCase: { id: this.props.match.params.id },
      documents: this.state.draftDocumentObjects,
    };

    const blocks =  convertToRaw(this.state.editorState.getCurrentContent());
    // this is the mapping of image w/c contains the the blob of the image.
    // We will change it into the file location on the file system after saving.
    const entityMap = blocks.entityMap;

    blocks.entityMap = entityMap;

    // convert blocks to JSON
    let messageJson = JSON.stringify(blocks);

    // save to db the updated message
    message.message = messageJson;

    // Checks if the message is a draft message, if not use post
    if (message.id !== null) {
      message = await Axios.clientService
        .put(ApiEnums.Message, message)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          this.errorHandler("Saving/Sending Message", error);
        });
    } else {
      message = await Axios.clientService
        .post(ApiEnums.Message, message)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          this.errorHandler("Saving/Sending Message", error);
        });
    }


    // save draft document
    if (!isDraft) {
      for (const document of this.state.draftDocumentObjects) {
        document.id = null;
        document.message = { id: message.id };
        await Axios.clientService
          .post(ApiEnums.Document, document)
          .catch((error) => {
            this.errorHandler("File Upload", error);
          });
      }
    }

    // save all attached file
    for (const file of combinedFilesArray) {
      let fileName = file.name;
      let blob = await this.toBase64(file);
      let document = {
        fileName: fileName,
        file: blob.split(",")[1],
        fileContentType: file.type,
        message: { id: message.id },
      };

      await Axios.clientService
        .post(ApiEnums.Document, document)
        .catch((error) => {
          this.errorHandler("File Upload", error);
        });
    }

    // create status for message either draft or sent depending on parameter;
    let messageStatus = {
      id:
        this.state.previousMessageStatus !== null
          ? this.state.previousMessageStatus?.id
          : null,
      statusOfMessage: isDraft
        ? MessageStatusEnum.DRAFT
        : MessageStatusEnum.SENT,
      dateTime: new moment().toISOString(),
      message: { id: message.id },
    };

    if (this.state.previousMessageStatus !== null) {
      await Axios.clientService
        .put(ApiEnums.MessageStatus, messageStatus)
        .then(() => {
          this.redirectToPreviousPage(isDraft);
        })
        .catch((error) => {
          this.errorHandler("Creating Message Status", error);
        });
    } else {
      await Axios.clientService
        .post(ApiEnums.MessageStatus, messageStatus)
        .then(() => {
          this.redirectToPreviousPage(isDraft);
        })
        .catch((error) => {
          this.errorHandler("Creating Message Status", error);
        });
    }
    this.props.updateWhistleblowercaseState &&
      (await this.props.updateWhistleblowercaseState());
  }

  // Redirects user to previous page after the sending of message is finished
  redirectToPreviousPage(isDraft) {
    this.props.history.push({
      pathname: `/cases/case-details/${this.props.match.params.id}`,
      state: {
        messageSuccessMessage: isDraft
          ? this.translation(`messageComponent.messageSavedAsDraft`)
          : this.translation(`messageComponent.messageSent`),
      },
    });
  }

  // Gets the file location of the uploaded image/file
  getFileLocation(passedId, uploadType, formData, config) {
    return Axios.clientService
      .post(
        `${ApiEnums.UploadController}/${uploadType}?messageId=${passedId}`,
        formData,
        config
      )
      .catch((error) => {
        this.errorHandler("File Upload", error);
      });
  }

  // Deletes the draft document
  deleteDraftDocument(document) {
    this.toggleModalDelete();
    this.modalDeleteEvent = async () => {
      Axios.clientService
        .delete(`${ApiEnums.Document}/${document.id}`)
        .then(() => {
          let draftDocumentObjects = this.state.draftDocumentObjects;
          let draftDocumentFiles = this.state.draftDocumentFiles;

          draftDocumentObjects = removeByAttr(
            draftDocumentObjects,
            "id",
            document.id
          );

          this.setState({
            draftDocumentObjects: draftDocumentObjects,
            draftDocumentFiles: this.removeFileFromArray(
              document.fileName,
              draftDocumentFiles
            ),
          });
        })
        .catch((error) => {
          this.errorHandler("Remove Document from Draft", error);
        });
    };
  }

  // Removes the file from the array of files
  removeFileFromArray(fileName, draftDocumentFiles) {
    let newFileArray = [];
    draftDocumentFiles.forEach((file) => {
      if (file.name !== fileName) {
        newFileArray.push(file);
      }
    });
    return newFileArray;
  }

  // Commands to run when sending the message
  onSubmit(event) {
    event.preventDefault();
    this.saveMessage(false);
  }

  // Returns the user to the previous page upon cancellation of message creation
  cancelMessage() {
    this.props.history.push({
      pathname: `/cases/case-details/${this.props.match.params.id}`,
    });
  }

  // Handles file uploads
  attachFiles(e) {
    let attachedFiles = this.state.attachedFiles;
    let maxFileSize = 104857600; // 100 MB max limit
    let filesOverMaxFileSize = false;
    let files = e.target.files;
    if (files.length === 0) {
      return;
    }
    for (let i = 0; i < files.length; i++) {
      let file = files.item(i);
      if (file.size > maxFileSize) {
        filesOverMaxFileSize = true;
        this.setState({
          errorMessage: this.translation(`errorMessages.maximumFileSizeLimit`, {
            fileName: file.name,
          }),
        });

        setTimeout(() => {
          this.setState({
            errorMessage: "",
          });
        }, 5000);

        return;
      }
      attachedFiles.push(file);
    }

    // clear uploaded files
    if (filesOverMaxFileSize) {
      e.target.value = "";
      e.target.files = null;
      return;
    }

    this.setState({ attachedFiles: attachedFiles });
  }

  removeFile(file) {
    let { attachedFiles } = this.state;
    attachedFiles = attachedFiles.filter(
      (attachedFile) => attachedFile !== file
    );

    this.setState({ attachedFiles: attachedFiles });
  }

  // Catches the errors encountered within this page
  errorHandler(currentOperation, error) {
    if (currentOperation === "Message Component Setup") {
      this.mainError = this.translation(
        `errorMessages.messageComponentSetupError`
      );
    } else if (currentOperation === "Saving/Sending Message") {
      this.mainError = this.translation(`errorMessages.messageSaveSendError`);
    } else if (currentOperation === "File Upload") {
      this.mainError = this.translation(
        `errorMessages.messageAttachmentUploadError`
      );
    } else if (currentOperation === "Creating Message Status") {
      this.mainError = this.translation(
        `errorMessages.messageStatusCreationError`
      );
    } else if (currentOperation === "Remove Document from Draft") {
      this.mainError = this.translation(`errorMessages.removeDocumentError`);
    }
    this.errorReason = this.translation(`errorMessages.failedToCommunicate`);
    this.errorResponse = error.message;
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  }

  // Commands to run when saving the message as a draft
  saveAsDraft() {
    this.saveMessage(true);
  }

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  prepareTableData = (docs) => {
    if (Array.isArray(docs) && docs.length > 0) {
      let newTableData = [];

      docs.forEach((doc) => {
        let entry = {
          fileName: doc.fileName,
          doc: doc,
        };

        newTableData.push(entry);
      });

      return newTableData;
    } else {
      return [];
    }
  };

  displayForm(preparedColumns) {
    return (
      <Form onSubmit={this.onSubmit}>
        <FormGroup>
          <Label>{this.translation(`commonText.message`)}</Label>
          <Editor
            editorClassName="form-control"
            onEditorStateChange={this.onEditorStateChange}
            editorState={this.state.editorState}
            editorStyle={{
              fontSize: "10pt",
              fontFamily: "GT-Walsheim-Pro, Arial,Helvetica,sans-serif",
              minHeight: 100,
            }} // bug on ordered list font size
            toolbar={{
              options: [
                "inline",
                "list",
                "textAlign",
                "fontSize",
                "fontFamily",
              ],
              inline: {
                options: [
                  "bold",
                  "italic",
                  "underline",
                  "strikethrough",
                  "superscript",
                  "subscript",
                ],
              },
              fontSize: {
                options: [
                  "8pt",
                  "9pt",
                  "10pt",
                  "11pt",
                  "12pt",
                  "14pt",
                  "16pt",
                  "18pt",
                  "24pt",
                  "30pt",
                  "36pt",
                  "48pt",
                  "60pt",
                  "72pt",
                  "96pt",
                ],
              },
              fontFamily: {
                options: [
                  "GT-Walsheim-Pro",
                  "Arial",
                  "Georgia",
                  "Impact",
                  "Tahoma",
                  "Times New Roman",
                  "Verdana",
                ],
              },
            }}
          />
        </FormGroup>
        <FormGroup>
          <Input
            color="primary"
            className="mr-1"
            id="attachFile"
            type="file"
            multiple
            style={{ display: "none" }}
            onChange={this.attachFiles}
          />
          <Row>
            <Col>
              <label
                htmlFor="attachFile"
                className="btn btn-primary cursor-pointer"
                id="attachFileButton"
              >
                <FontAwesomeIcon icon={faPaperclip} />{" "}
                {this.translation(`messageComponent.attachFiles`)}
              </label>
            </Col>
          </Row>
          <UncontrolledTooltip target="attachFileButton">
            {this.translation(`messageComponent.attachFiles`)}
          </UncontrolledTooltip>
          {this.state.attachedFiles &&
            this.state.attachedFiles.map((file) => {
              return (
                <Row key={`file-${file.name}`} className="mb-1">
                  <Col sm="3" xs="6">
                    <Label>{file.name}</Label>
                  </Col>
                  <Col>
                    <Button
                      outline
                      color="primary"
                      className="mr-1"
                      onClick={() => {
                        this.removeFile(file);
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} />{" "}
                      {this.translation(`commonButtonTexts.remove`)}
                    </Button>
                  </Col>
                </Row>
              );
            })}
        </FormGroup>
        {Array.isArray(this.state.draftDocumentObjects) &&
          !isEmpty(this.state.draftDocumentObjects) && (
            <div>
              <br />
              <DynamicTable
                data={this.prepareTableData(this.state.draftDocumentObjects)}
                columns={preparedColumns}
                deleteAction={this.deleteDraftDocument}
                infiniteScroll
                dropdown={{
                  actions: [
                    {
                      label: this.translation(`commonButtonTexts.delete`),
                      actionFunc: "delete",
                      actionProps: "doc",
                    },
                  ],
                }}
              />
            </div>
          )}
        {this.props.caseId ? (
          ""
        ) : (
          <FormGroup className="">
            <Button
              type="submit"
              color="primary"
              className="mr-1"
              id="message-send-button"
              disabled={
                !(
                  this.state.editorState.getCurrentContent().hasText() ||
                  this.state.attachedFiles.length > 0
                )
              }
            >
              <FontAwesomeIcon icon={faPaperPlane} />{" "}
              {this.translation(`messageComponent.send`)}
            </Button>
            <Button
              color="primary"
              className="mr-1"
              onClick={() => {
                this.saveAsDraft();
              }}
              id="message-savedraft-button"
            >
              <FontAwesomeIcon icon={faSave} />{" "}
              {this.translation(`messageComponent.saveAsDraft`)}
            </Button>
            <Button
              outline
              color="primary"
              className="mr-1"
              onClick={() => {
                this.cancelMessage();
              }}
              id="message-cancel-button"
            >
              <FontAwesomeIcon icon={faTimes} />{" "}
              {this.translation(`commonButtonTexts.cancel`)}
            </Button>
            <UncontrolledTooltip target="message-cancel-button">
              {this.translation(`commonButtonTexts.cancel`)}
            </UncontrolledTooltip>
            <UncontrolledTooltip target="message-send-button">
              {this.translation(`messageComponent.send`)}
            </UncontrolledTooltip>
            <UncontrolledTooltip target="message-savedraft-button">
              {this.translation(`messageComponent.saveAsDraft`)}
            </UncontrolledTooltip>
          </FormGroup>
        )}
      </Form>
    );
  }
  render() {
    const preparedColumns = [
      {
        type: "data",
        header: this.translation(`messageComponent.draftDocument`),
        accessor: "fileName",
        show: "true",
        filterkey: "fileName",
        showsearch: "true",
      },
      {
        type: "Dropdown",
        header: this.translation(`commonText.menu`),
        show: "true",
      },
    ];
    return this.props.caseId ? (
      this.displayForm(preparedColumns)
    ) : (
      <>
        <Container fluid>
          <Card>
            <LoadingIndicator />
            <CardBody>
              {this.state.errorMessage !== "" && (
                <FadeAlert color="danger">{this.state.errorMessage}</FadeAlert>
              )}
              {this.displayForm(preparedColumns)}
              <ModalDelete
                isOpen={this.state.showModalDelete}
                onClose={this.toggleModalDelete}
                event={this.modalDeleteEvent}
                modalTitle={this.translation(
                  `messageComponent.removeDraftDocument`
                )}
                modalBodyText={this.translation(
                  `messageComponent.removeDraftDocumentMessage`
                )}
              ></ModalDelete>
              <ModalError
                isOpen={this.state.showModalError}
                onClose={this.toggleModalError}
                mainError={this.mainError}
                errorReason={this.errorReason}
                errorResponse={this.errorResponse}
                modalTitle="Error"
              ></ModalError>
            </CardBody>
          </Card>
        </Container>
      </>
    );
  }
}

export default withRouter(withTranslation()(Message));
