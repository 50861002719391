import { faHistory, faFolderOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import ModalOK from "../../components/modals/ModalOK";
import { formatDate, LoadingIndicator } from "../../helpers/GenericHelper";
import DynamicTable from "../DynamicTable";
import CaseStatusEnum from "../../enums/CaseStatusEnum";
import Axios from "../../axios/Axios";
import moment from "moment";
const defaultFilterWidth = "100px";

/*
 * Class for generating the card for Case Status
 */
class CaseStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStatus: "",
      currentStatusDate: "",
      previousStatuses: [],
      refresher: this.props.refresher,

      showModalOK: false,
    };
    this.caseData = this.props.caseData;

    this.translation = this.props.t;
    this.firstIndex = 0;

    this.toggleModalOK = this.toggleModalOK.bind(this);
    this.setCaseStatus = this.setCaseStatus.bind(this);

    // Observer for resizing element, use for making the caseDetailCard height same as this card
    this.resize_ob = new ResizeObserver(function (entries) {
      if (
        document.getElementById("caseDetailCard") &&
        document.getElementById("caseStatusCard")
      ) {
        document.getElementById("caseDetailCard").style.height =
          window.getComputedStyle(
            document.getElementById("caseStatusCard")
          ).height;
      }
    });
  }

  componentDidUpdate(prevprops) {
    if (prevprops.refresher !== this.props.refresher) {
      this.caseData = this.props.caseData;
      this.componentDidMount();
    }
  }

  // Toggles the Boolean that affects appearance of modal OK dialog box
  toggleModalOK() {
    this.setState({
      showModalOK: !this.state.showModalOK,
    });
  }

  // Commands to execute when the component is mounting.
  componentDidMount() {
    this.setState({
      currentStatus: this.caseData.caseStatuses[this.firstIndex].statusOfCase,
      currentStatusDate: this.caseData.caseStatuses[this.firstIndex].dateTime,
      previousStatuses: this.caseData.caseStatuses,
    });
    this.resize_ob.observe(document.getElementById("caseStatusCard"));
  }

  componentWillUnmount() {
    this.resize_ob.disconnect();
  }

  prepareTableData = (statuses) => {
    if (Array.isArray(statuses) && statuses.length > 0) {
      let newTableData = [];

      statuses.forEach((status) => {
        let entry = {
          status: this.translation(
            `enumTranslation.${status.statusOfCase.toLowerCase()}`
          ),
          date: formatDate(status.dateTime, true),
        };

        newTableData.push(entry);
      });

      return newTableData;
    } else {
      return [];
    }
  };

  setCaseStatus() {
    let newStatus = {
      whistleblowerCase: this.props.caseData,
      statusOfCase: CaseStatusEnum.OPEN,
      dateTime: moment(),
    };
    Axios.clientService.post(`case-statuses`, newStatus).then((response) => {
      let previousStatuses = this.state.previousStatuses;
      previousStatuses.push(response.data);
      this.setState({
        currentStatus: response.data.statusOfCase,
        currentStatusDate: response.data.dateTime,
        previousStatuses: previousStatuses,
      });
    });
  }

  render() {
    let preparedColumns = [
      {
        type: "data",
        header: this.translation(`commonText.statusText`),
        accessor: "status",
        show: "true",
        filterkey: "status",
        showsearch: "true",
      },
      {
        type: "data",
        header: this.translation(`commonText.date`),
        accessor: "date",
        show: "true",
        filterkey: "date",
        alignleft: "true",
        showsearch: "true",
      },
    ];
    var previousStatuses = (
      <CardBody style={{ padding: "0px" }}>
        {Array.isArray(this.state.previousStatuses) &&
          this.state.previousStatuses.length > 0 && (
            <DynamicTable
              data={this.prepareTableData(this.state.previousStatuses)}
              columns={preparedColumns}
              removeMenuPortalTarget={true}
              infiniteScroll
            />
          )}
      </CardBody>
    );
    return (
      <>
        <Card id="caseStatusCard">
          <LoadingIndicator />
          <CardHeader>
            <div className="card-actions float-right">
              {CaseStatusEnum.DRAFT === this.state.currentStatus ? (
                <Button
                  color="primary"
                  onClick={this.setCaseStatus}
                  className="mr-1"
                  id="markCaseAsOpen"
                >
                  <FontAwesomeIcon icon={faFolderOpen} />{" "}
                  {this.translation(`caseStatus.markCaseAsOpen`)}
                  <UncontrolledTooltip target="markCaseAsOpen">
                    {this.translation(`caseStatus.markCaseAsOpen`)}
                  </UncontrolledTooltip>
                </Button>
              ) : null}

              <Button
                color="primary"
                onClick={() => this.toggleModalOK()}
                id="history-button"
              >
                <FontAwesomeIcon icon={faHistory} />{" "}
                {this.translation(`caseStatus.viewHistoryButton`)}
              </Button>
              <UncontrolledTooltip target="history-button">
                {this.translation(`caseStatus.viewHistoryButton`)}
              </UncontrolledTooltip>
            </div>
            <CardTitle>
              <h1>{this.translation(`commonText.caseStatus`)}</h1>
            </CardTitle>
          </CardHeader>
          <CardBody>
            <Table>
              <tbody>
                <tr>
                  <th>{this.translation(`caseStatus.latestCaseStatusText`)}</th>
                  <td>
                    {this.translation(
                      `enumTranslation.${this.state.currentStatus.toLowerCase()}`
                    )}
                  </td>
                </tr>
                <tr>
                  <th>{this.translation(`caseStatus.dateTimeLastChange`)}</th>
                  <td>{formatDate(this.state.currentStatusDate, true)}</td>
                </tr>
              </tbody>
            </Table>
          </CardBody>
        </Card>
        <ModalOK
          isOpen={this.state.showModalOK}
          onClose={this.toggleModalOK}
          modalTitle={this.translation(`caseStatus.previousCaseStatusHeader`)}
          modalBodyText={previousStatuses}
        ></ModalOK>
      </>
    );
  }
}

export default withRouter(withTranslation()(CaseStatus));
