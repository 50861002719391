import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import FadeAlert from "../FadeAlert";
import ModalError from "../modals/ModalError";
import ModalForm from "../modals/ModalForm";
import MetaInformationForm from "./MetaInformationForm";

/*
 * Class for generating the card for Meta Information
 */
class MetaInformationCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      case: {},
      caseTitle: null,

      detailSuccessMessage: "",

      editCase: false,
      showModalError: false,
      modalForm: null,
      showModalForm: false,
      errors: {},
    };

    this.translation = this.props.t;

    this.mainError = "";
    this.errorReason = "";
    this.errorResponse = "";

    this.editMetaInformation = this.editMetaInformation.bind(this);
    this.setSuccessMessage = this.setSuccessMessage.bind(this);
    this.toggleModalError = this.toggleModalError.bind(this);
    this.toggleModalForm = this.toggleModalForm.bind(this);

    this.errorHandler = this.errorHandler.bind(this);
  }

  componentDidMount() {
    // Open meta information modal on newly created case
    if (queryString.parse(this.props.location.search).created) {
      this.setState({
        modalForm: (
          <MetaInformationForm
            whistleblowerCase={this.props.caseData}
            onSubmit={this.toggleModalForm}
            setSuccessMessage={this.setSuccessMessage}
            isEdit={false}
            updateWhistleblowercaseState={
              this.props.updateWhistleblowercaseState
            }
            updateCaseStatus={this.props.updateCaseStatus}
          />
        ),
        modalTitle: this.translation(`metaInformation.title`),
      });
      this.toggleModalForm();
    }
  }

  toggleModalForm() {
    this.setState({
      showModalForm: !this.state.showModalForm,
    });

    this.props.updateCaseStatus();
  }

  // Toggles the Boolean that affects appearance of modal error dialog box
  toggleModalError() {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  }

  // Catches the errors encountered within this page
  errorHandler(currentOperation, error) {
    this.errorReason = this.translation(`errorMessages.failedToCommunicate`);
    this.errorResponse = error.message;
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  }

  editMetaInformation() {
    this.setState({
      modalForm: (
        <MetaInformationForm
          whistleblowerCase={this.props.caseData}
          onSubmit={this.toggleModalForm}
          setSuccessMessage={this.setSuccessMessage}
          isEdit={true}
        />
      ),
      modalTitle: this.translation(`metaInformation.title`),
    });
    this.toggleModalForm();
  }

  setSuccessMessage(message) {
    this.setState({ detailSuccessMessage: message });
  }

  render() {
    let { caseData } = this.props;
    return (
      <>
        <Card>
          <CardHeader>
            {this.state.detailSuccessMessage !== "" && (
              <FadeAlert color="success" style={{ "text-indent": "10px" }}>
                {this.state.detailSuccessMessage}
              </FadeAlert>
            )}
            <CardTitle>
              <div className="card-actions float-right">
                <Button
                  id="editMetaButton"
                  color="primary"
                  size="m"
                  onClick={this.editMetaInformation}
                >
                  <FontAwesomeIcon icon={faEdit} />{" "}
                  {this.translation(`commonButtonTexts.edit`)}
                </Button>
                <UncontrolledTooltip target="editMetaButton">
                  {this.translation(`commonButtonTexts.edit`)}
                </UncontrolledTooltip>
              </div>

              <h1>{this.translation(`metaInformation.title`)} </h1>
            </CardTitle>
          </CardHeader>
          <CardBody>
            <Table>
              <tbody style={{ whiteSpace: "pre-line" }}>
                <tr>
                  <th style={{ width: "30%" }}>
                    {this.translation(`commonText.contactData`)}:
                  </th>
                  <td>{caseData.metaInformation?.contactData ?? "-"}</td>
                </tr>
                <tr>
                  <th style={{ width: "30%" }}>
                    {this.translation(`commonText.violation`)}:
                  </th>
                  <td>{caseData.metaInformation?.violation ?? "-"}</td>
                </tr>
                <tr>
                  <th style={{ width: "30%" }}>
                    {this.translation(`commonText.occurrenceTime`)}:
                  </th>
                  <td>{caseData.metaInformation?.occurenceTime ?? "-"}</td>
                </tr>
                <tr>
                  <th style={{ width: "30%" }}>
                    {this.translation(`commonText.location`)}:
                  </th>
                  <td>{caseData.metaInformation?.location ?? "-"}</td>
                </tr>
                <tr>
                  <th style={{ width: "30%" }}>
                    {this.translation(`commonText.involvedPeople`)}:
                  </th>
                  <td>{caseData.metaInformation?.involvedPeople ?? "-"}</td>
                </tr>
                <tr>
                  <th style={{ width: "30%" }}>
                    {this.translation(`commonText.witness`)}:
                  </th>
                  <td>{caseData.metaInformation?.witness ?? "-"}</td>
                </tr>
                <tr>
                  <th style={{ width: "30%" }}>
                    {this.translation(`commonText.expectations`)}:
                  </th>
                  <td>{caseData.metaInformation?.expectations ?? "-"}</td>
                </tr>
              </tbody>
            </Table>
          </CardBody>
        </Card>
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.mainError}
          errorReason={this.errorReason}
          errorResponse={this.errorResponse}
          modalTitle="Error"
        ></ModalError>
        <ModalForm
          isOpen={this.state.showModalForm}
          eventOnClose={this.toggleModalForm}
          modalTitle={this.state.modalTitle}
          ref={this.state.modalForm}
        >
          {this.state.modalForm}
        </ModalForm>
      </>
    );
  }
}

export default withRouter(withTranslation()(MetaInformationCard));
