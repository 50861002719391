import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import Axios from "../../axios/Axios";
import CaseDetails from "../../components/cases/CaseDetails";
import CaseStatus from "../../components/cases/CaseStatus";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import MessageList from "../../components/messages/MessageList";
import ModalError from "../../components/modals/ModalError";
import ApiEnums from "../../enums/ApiEnums";
import { GenerateBreadcrumb } from "../../helpers/GenerateBreadcrumb";
import { isEmpty } from "../../helpers/GenericHelper";
import { store } from "../../redux/store/index";
import MetaInformationCard from "../../components/cases/MetaInformationCard";
import axios from "axios";

/*
 * Class for the page for case details
 */
class CaseDetailsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      caseData: {},
      hasAccess: false,

      showModalError: false,
      refresher: false,
    };
    this.translation = this.props.t;
    this.id = this.props.match.params.id;

    this.mainError = "";
    this.errorReason = "";
    this.errorResponse = "";

    this.getWhistleblowerCaseData = this.getWhistleblowerCaseData.bind(this);
    this.updateCaseStatus = this.updateCaseStatus.bind(this);
    this.toggleModalError = this.toggleModalError.bind(this);
    this.errorHandler = this.errorHandler.bind(this);
    this.messageListRef = React.createRef();
  }

  // Toggles the Boolean that affects appearance of modal error dialog box
  toggleModalError() {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  }

  async updateCaseStatus() {
    this.componentDidMount().then(() => this.setState({
      refresher: !this.state.refresher,
    }));
  }

  async componentDidMount() {
    let storeState = store.getState();
    let accountDetails = storeState.account.accountDetails;

    let caseDataResponse = await Axios.clientService
    .get(
      `${ApiEnums.WhistleblowerCase}/${this.id}`
    )
    .then((response) => { return response.data})
    .catch((error) => {
      this.errorHandler("Get Case Data", error);
    });

    this.setState({
      caseData: caseDataResponse,
      hasAccess: true,
    });
    await this.getWhistleblowerCaseData()
    return;
  }

  async getWhistleblowerCaseData(){
    await Axios.clientService.get(`whistleblower-case-messages/${this.id}`).then(response=>{
      this.setState({
        messages: response.data
      })
    })
  }

  // Catches the errors encountered within this page
  errorHandler(currentOperation, error) {
    if (currentOperation === "Get Case Data") {
      this.mainError = this.translation(`errorMessages.caseDataRetrievalError`);
    }
    if (error.response?.data.errorKey === "userunauthorized") {
      this.errorReason = this.translation(`errorMessages.accessForbidden`);
    } else {
      this.errorReason = this.translation(`errorMessages.failedToCommunicate`);
    }
    this.errorResponse = error.message;
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  }

  render() {
    return (
      <Container fluid>
        <Header>
          <HeaderTitle>
            {this.translation(`caseDetailsPage.caseDetailsHeader`)}
          </HeaderTitle>
          {GenerateBreadcrumb(this.props.location.pathname, "Cases", "/")}
        </Header>
        <Row>
          <Col lg="6" xl="6">
            {!isEmpty(this.state.caseData) && this.state.hasAccess && (
              <CaseDetails caseData={this.state.caseData} />
            )}
          </Col>
          <Col lg="6" xl="6">
            {!isEmpty(this.state.caseData) && this.state.hasAccess && (
              <CaseStatus caseData={this.state.caseData} refresher={this.state.refresher}/>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            {!isEmpty(this.state.caseData) && this.state.hasAccess && (
              <MetaInformationCard caseData={this.state.caseData} updateWhistleblowercaseState={this.getWhistleblowerCaseData} updateCaseStatus={this.updateCaseStatus}/>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
          {this.state.hasAccess && (
            <MessageList id={this.id} messages={this.state.messages} parentPage="Case Details" onRef={ref => this.messageListRef = ref}/>
          )}
          </Col>
        </Row>
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.mainError}
          errorReason={this.errorReason}
          errorResponse={this.errorResponse}
          modalTitle="Error"
        ></ModalError>
      </Container>
    );
  }
}

export default withRouter(withTranslation()(CaseDetailsPage));
