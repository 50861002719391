import { reducer as toastr } from "react-redux-toastr";
import { combineReducers } from "redux";
import account from "./AccountReducers";
import sidebar from "./sidebarReducers";
import theme from "./themeReducer";

export default combineReducers({
  account,
  sidebar,
  theme,
  toastr
});
