import { trackPromise } from "react-promise-tracker";
import axios from "./Axios-Interceptors";

const BaseUrl = "/";

const clientServiceApiUrl = "api/";

export const backendUrl = BaseUrl + clientServiceApiUrl;

const axiosMethods = {
  account: {
    get(url) {
      return trackPromise(axios.get(BaseUrl + "api/" + url));
    },
    post(url, data) {
      return trackPromise(axios.post(BaseUrl + "api/" + url, data));
    },
  },
  clientService: {
    get(url, config) {
      return trackPromise(
        axios.get(BaseUrl + clientServiceApiUrl + url, config)
      );
    },
    post(url, data, config) {
      return trackPromise(
        axios.post(BaseUrl + clientServiceApiUrl + url, data, config)
      );
    },
    put(url, data) {
      return trackPromise(axios.put(BaseUrl + clientServiceApiUrl + url, data));
    },
    delete(url) {
      return trackPromise(axios.delete(BaseUrl + clientServiceApiUrl + url));
    },
    all(urlarray) {
      return trackPromise(axios.all(urlarray));
    },
    getCount(api, idToGet, id) {
      return trackPromise(
        axios.get(
          BaseUrl + clientServiceApiUrl + `${api}/count?${idToGet}.equals=${id}`
        )
      );
    },
  },
};

export default axiosMethods;
