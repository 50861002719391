import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import i18n from "../i18n";

/**
 * Generate BreadCrumb based on url.
 * @param locationPath
 * the location path of the page where breadcrumb need to be generated.
 * @param mainAppName
 * the main app where the page is included ("Sales Management", "Project Resource Management", "Role Management", etc..)
 * @returns <Breadcrumb></Breadcrumb>.
 */
export function GenerateBreadcrumb(locationPath, mainAppName, parentLink) {
  let locationArray = locationPath.split("/");
  let linksAndNames = [
    {
      name: mainAppName,
      link: `${parentLink}`,
    },
  ];
  let path = `/${locationArray[1]}`;
  for (var index = 2; index < locationArray.length; index++) {
    let part = locationArray[index].toLowerCase();
    // capitalize every first letter of every word and replace '-' with space
    part = part.charAt(0).toUpperCase() + part.slice(1);
    part = part.replace(/-/g, " ");
    let pageName = "";
    for (let letter = 0; letter < part.length; letter++) {
      if (part[letter] === " ") {
        pageName += part[letter];
        letter += 1;
        pageName += part.charAt(letter).toUpperCase();
      } else {
        pageName += part[letter];
      }
    }

    if (index + 1 < locationArray.length) {
      if (isNaN(locationArray[index + 1])) {
        path += `/${locationArray[index]}`;
        linksAndNames.push({
          name: pageName,
          link: path,
        });
      } else {
        path += `/${locationArray[index]}/${locationArray[index + 1]}`;
        linksAndNames.push({
          name: pageName,
          link: path,
        });
        index++;
      }
    } else {
      path += `/${locationArray[index]}`;
      linksAndNames.push({
        name: pageName,
        link: path,
      });
    }
  }

  return (
    <Breadcrumb>
      {linksAndNames.map((page) =>
        page.link === locationPath ? (
          <BreadcrumbItem active key={page.name}>
            {translationHandler(page.name)}
          </BreadcrumbItem>
        ) : (
          <BreadcrumbItem key={page.name}>
            <Link to={page.link}>{translationHandler(page.name)}</Link>
          </BreadcrumbItem>
        )
      )}
    </Breadcrumb>
  );
}

// Function for handling the translation of the page name sent to the breadcrumb links
function translationHandler(pageName) {
  switch (pageName) {
    case "Cases":
      return i18n.t(`commonText.cases`);
    case "Case Details":
      return i18n.t(`caseDetailsPage.caseDetailsHeader`);
    case "New Message":
      return i18n.t(`newMessagePage.newMessage`);
    default:
      return "Page";
  }
}
