import Cookies from "js-cookie";
import React from "react";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import { PersistGate } from "redux-persist/integration/react";
import i18n from "./i18n";
import { persistor, store } from "./redux/store/index";
import Routes from "./routes/Routes";
import axios from "./axios/Axios";
import sessionStorage from "./axios/SessionStorage";
import localStorage from "./axios/LocalStorage";
import { isEmpty } from "./helpers/GenericHelper";
import { language } from "./helpers/Constants";
import { setCompanyDetails, resetAccountDetails, setAccountDetails } from "./redux/actions/AccountActions";
import { isArrayOrNodeList } from "reactstrap/lib/utils";
import { isHttps } from ".";




class App extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      authenticated: false
    };
  }

  async componentDidMount() {

    let cookieLanguage = Cookies.get(language);
    if (
      (localStorage.getAccessToken() != null ||
        localStorage.getAccessToken() != undefined) &&
      (sessionStorage.getAccessToken() == null ||
        sessionStorage.getAccessToken() == undefined)
    ) {
      sessionStorage.setToken(localStorage.getAccessToken());
    }

    // Obtain the company information
    await axios.clientService.get("company-information").then((response) => {
      if (!isEmpty(response.data)) {
        let responseInformation = response.data;
        if (isArrayOrNodeList(responseInformation?.languages) && !isEmpty(responseInformation.languages)) {
          let defaultLanguage = responseInformation.languages[0].toLowerCase();
          // If cookie language is not set or not part of the available languages it will be set to the default language
          if (cookieLanguage === null || cookieLanguage === undefined || !responseInformation.languages.some((availableLanguage) => availableLanguage.toLowerCase() === cookieLanguage)) {
            Cookies.set(
              language,
              defaultLanguage,
              { secure: isHttps, sameSite: 'lax' }
            );
            i18n.changeLanguage(defaultLanguage);
            cookieLanguage = defaultLanguage;
          }
        }

        // Translate the contact information
        try {
          let parser = JSON.parse(responseInformation.miscInformation.contact);
          responseInformation.miscInformation.contact = parser[cookieLanguage];
        } catch (e) {
          // Intentionaly blank, the value should only be changed if it's a valid JSON
        }
        // Translate the privacy information
        try {
          let parser = JSON.parse(responseInformation.miscInformation.privacy);
          responseInformation.miscInformation.privacy = parser[cookieLanguage];
        } catch (e) {
          // Intentionaly blank, the value should only be changed if it's a valid JSON
        }

        // Translate the imprint information
        try {
          let parser = JSON.parse(responseInformation.miscInformation.imprint);
          responseInformation.miscInformation.imprint = parser[cookieLanguage];
        } catch (e) {
          // Intentionaly blank, the value should only be changed if it's a valid JSON
        }

        // Translate the company name
        try {
          let parser = JSON.parse(
            responseInformation.companyInformation.companyName
          );
          responseInformation.companyInformation.companyName =
            parser[cookieLanguage];
        } catch (e) {
          // Intentionaly blank, the value should only be changed if it's a valid JSON
        }

        // Translate the provider name
        try {
          let parser = JSON.parse(
            responseInformation.companyInformation.providerName
          );
          responseInformation.companyInformation.providerName =
            parser[cookieLanguage];
        } catch (e) {
          // Intentionaly blank, the value should only be changed if it's a valid JSON
        }
        store.dispatch(setCompanyDetails(responseInformation));
      }
    }).catch((error) => {
      console.error(error);
    });;

    i18n.changeLanguage(cookieLanguage);

    await axios.account.get(`account`).then((response) => {
      this.setState({authenticated: true});
      // Remove `active` className from `.splash` element in `public/index.html`
      !document.querySelector(".splash") ||
        document.querySelector(".splash").classList.remove("active");
        store.dispatch(setAccountDetails(response.data))
    }).catch(() => {
      sessionStorage.clearToken();
      localStorage.clearToken();
      persistor.purge();
      store.dispatch(resetAccountDetails());
      this.setState({authenticated: true});
      if (window.location.pathname !== "/sign-in"){
        window.location.href = "/sign-in";
      }
      else {
        !document.querySelector(".splash") ||
          document.querySelector(".splash").classList.remove("active");
      }
    })
  }

  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          { this.state.authenticated && 
            <Routes />
          }
          <ReduxToastr
            timeOut={5000}
            newestOnTop={true}
            position="top-right"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
            closeOnToastrClick
          />
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
